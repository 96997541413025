import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";
import profileBackImg from "../../assets/images/profileBackImg.jpg";

const profileStyle = {
  header: {
    color: "#2e2e2e",
    fontSize: "50px",
    marginBottom: "25px",
  },
  profileBackImg: {
    position: "relative",
    backgroundImage: "url(" + profileBackImg + ")",
    height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center",
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      height: "40vh",
    },

    "&:before": {
      zIndex: 1,
      position: "absolute",
      backgroundColor: "#000000ba",
      content: `""`,
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
  userName: {
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    color: "#FFFFFF",
    zIndex: 2,
  },
  userEmail: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: "#FFFFFF",
    zIndex: 2,
  },
  iconList: {
    position: "relative",
    height: "50px",
    width: "50px",
    display: "-webkit-box",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "5px solid #FFFFFF",
    borderRadius: "50%",
    overflow: "hidden",
    transition: "all 500ms ease",
    zIndex: "1",
    cursor: "pointer",
    marginRight: "5px",
    "&:after": {
      backgroundColor: "#f15e22",
      borderRadius: "30px",
      content: `''`,
      display: "block",
      height: "100%",
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      transform: "translate(0, -100%) ",
      transformOrigin: "bottom",
      transition: "all .5s ease-in-out",
      willChange: "transform",
      zIndex: -1,
    },
    "&:hover": {
      "& .profileText": {
        transition: "all .5s ease-in-out",
        color: "#FFFFFF",
      },
      "&:after": {
        transform: "translate(0, 0)",
      },
    },
  },
  profileText: {
    ...regularFont,
    fontWeight: "700",
    fontSize: "25px",
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      transition: "all .5s ease-in-out",
      color: "#f4875a",
    },
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  pagination: {
    marginTop: "40px",
    marginBottom: "20px",
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: `#000000 ! important`,
    },
  },
} as const;

export default profileStyle;
