import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getProfileDetails = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.PROFILE_DETAILS, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMyBlogs = async (page: number, per_page: number) => {
  const url = `${urls.MY_BLOGS}?page=${page}&per_page=${per_page}`;
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMyLikedBlogs = async (page: number, per_page: number) => {
  const url = `${urls.MY_LIKED_BLOGS}?page=${page}&per_page=${per_page}`;
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
