import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";
import topicsStyles from "../../Topics/Topics.styles";
import BlogList from "../../Topics/Components/BlogList/BlogList";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import * as topicsService from "../../Topics/Topics.service";
import { isTruthy, openErrorNotification } from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import { useParams } from "react-router-dom";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 30px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "30px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const SearchBlogs = () => {
  const classes = topicsStyles;
  const { search }: any = useParams();
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [dataPerPage, setDataPerPage] = useState<number>(12);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTopicData(search, dataPerPage, page);
  }, [search, dataPerPage, page]);

  const getTopicData = async (
    search: string,
    dataPerPage: number,
    page: number
  ) => {
    try {
      setBlogs([]);
      setIsLoading(true);
      const response = await topicsService.getTopicData(
        search,
        dataPerPage,
        page
      );
      setBlogs(response.blogs);
      setTotalPage(response.totalPages);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChange = (event: any) => {
    setDataPerPage(event.target.value);
  };

  const getBlogBox = () => {
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={3} justifyContent={"center"}>
          <BlogList blogs={blogs} is_latest={false} />
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={classes.paginationWrapper}
          >
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel>Blogs per page</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={dataPerPage}
                onChange={handleChange}
                input={<BootstrapInput />}
                defaultValue={12}
              >
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              sx={classes.pagination}
              showFirstButton
              showLastButton
              count={totalPage}
              color="primary"
              page={page}
              onChange={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Box>
      <Grid container justifyContent={"center"}>
        <Box pt={5} borderBottom={"double"}>
          <Typography sx={classes.header}>{search}</Typography>
        </Box>
      </Grid>
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={classes.mainContainer}>
          {getBlogBox()}
          <CustomLoader isLoading={isLoading} />
        </Grid>
      </Container>
    </Box>
  );
};

export default SearchBlogs;
