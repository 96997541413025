import {
  mediumFont,
  getRelativeFontSize,
  inputLabelRequiredColor,
  primaryBackgroundColor,
  theme,
  warningColor,
} from "../../../utils/styles";

const layoutStyles = {
  content: {
    width: "100%",
    marginTop: "120px",
    overflowX: "hidden",
    position: "relative",
    backgroundColor: primaryBackgroundColor,
  },
  root: {
    display: "flex",
    position: "relative",
    backgroundColor: primaryBackgroundColor,
  },
  dropZoneWrapper: {
    "& .MuiDropzoneArea-root": {
      width: "100%",
      minHeight: "70px",
      fontSize: "12px",
      borderRadius: "10px",
      borderWidth: "3px",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: getRelativeFontSize(2),
      ...mediumFont,
    },
  },
  warningContent: {
    color: warningColor,
    fontSize: getRelativeFontSize(),
    margin: "10px 0",
    ...mediumFont,
  },
  supportTicket: {
    position: "absolute",
    right: "25px",
    bottom: "0px",
    cursor: "pointer",
  },
  supportTicketClose: {
    position: "absolute",
    right: "10px",
    zIndex: "1",
  },
  supportTicketIcon: {
    position: "relative",
  },
  supportTicketTitleWrapper: {
    width: "100%",
    background: "#FCF5FF",
    display: "flex",
    justifyContent: "center",
    "& img": {
      [theme.breakpoints.down("lg")]: {
        width: "50%",
      },
    },
  },
  supportTicketTitle: {
    textAlign: "center",
    ...mediumFont,
    fontSize: getRelativeFontSize(14),
  },
  formInput: {
    width: "100%",
    "& .MuiDropzoneArea-root": {
      width: "100%",
      minHeight: "10px",
    },
  },
  label: {
    ...mediumFont,
    marginBottom: "8px",
  },
  star: {
    color: inputLabelRequiredColor,
    ...mediumFont,
  },
  selectStyle: {
    width: "100%",
    height: "45px",
    borderRadius: "10px",
    ...mediumFont,
    "&.Mui-focused fieldset": {
      borderColor: "#4B0150 !important",
    },
  },
  optionStyle: {
    ...mediumFont,
  },
  testAreaStyle: {
    borderColor: "rgba(0,0,0,0.2)",
    background: "none",
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important",
    },
  },
  testAreaStyleError: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important",
      borderColor: "red !important",
    },
  },
  submitButton: {
    width: "200px",
    margin: "20px 0",
  },
  previewChip: {
    marginTop: 1,
    padding: "15px 0px",
    justifyContent: "space-between",
  },

  errorStyle: {
    paddingLeft: "15px",
  },
  footer: {
    bottom: 0,
    alignItems: "center",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(-1),
    textAlign: "center",
    color: "#FFFFFF",
  },
} as const;

export default layoutStyles;
