import urls from "../../global/constants/UrlConstants";
import {
  getCallParams,
  getNoAuthCallParams,
  makeCall,
} from "../../utils/service";

export async function getTopicData(
  topic: string,
  dataPerPage: number,
  page: number
) {
  const url = `${urls.GET_DATA}?key=${topic}&page=${page}&per_page=${dataPerPage}`;
  try {
    const callParams = getNoAuthCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getSummary(blogsID: any) {
  try {
    const callParams = getNoAuthCallParams("POST", blogsID);
    const response: any = await makeCall(urls.GET_SUMMARY, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getBlogData(id: string) {
  const url = `${urls.GET_BLOG_DATA}?blog_id=${id}`;
  try {
    const callParams = getNoAuthCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getRecommendedBlogs(id: string) {
  const url = `${urls.GET_RECOMMENDED_BLOG_DATA}?blog_id=${id}`;
  try {
    const callParams = getNoAuthCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const likeBlogs = async (blogId: string, isLike: boolean) => {
  const likeData = {
    blogId,
    isLike,
  };
  try {
    console.log(likeData);

    const callParams = await getCallParams("POST", likeData);
    const response: any = await makeCall(urls.LIKE_BLOG, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
