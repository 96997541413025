import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import viewBlogStyle from "./ViewBlog.styles";
import { useEffect, useState } from "react";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import * as viewBlogServices from "./ViewBlog.service";
import { getRelativeFontSize } from "../../../utils/styles";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { CustomIcon } from "../../../global/components";
import { blogDetails } from "./ViewBlogHelper";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";

const ViewBlog = () => {
  const classes = viewBlogStyle;
  const id = window.location.pathname.split("/")[2];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [blogData, setBlogData] = useState(blogDetails);
  const title = window.location.pathname.split("/")[3];
  const baseURL = window.location.origin;

  let mainUrl = `/view-blog/${id}`;
  const facebook =
    "https://www.facebook.com/sharer/sharer.php?u=https://www.panaceinsights.com";
  let facebookLink = facebook + mainUrl;
  const linkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${baseURL}`;
  let linkedInLink = linkedIn + mainUrl;
  const twitter = `https://twitter.com/intent/tweet?url=${baseURL}`;
  let twitterLink = twitter + mainUrl;
  let mail =
    "mailto:?subject=" +
    `${title}` +
    ` | Panacea Insights&body=#Happy Breathing %0D%0ACheck out this blog ${baseURL}` +
    mainUrl;

  const shareButtons = [
    { name: "facebook", link: facebookLink, icon: FacebookOutlinedIcon },
    { name: "linkedin", link: linkedInLink, icon: LinkedInIcon },
    { name: "twitter", link: twitterLink, icon: TwitterIcon },
    { name: "mail", link: mail, icon: EmailOutlinedIcon },
    { name: "copyURL", link: "", icon: InsertLinkOutlinedIcon },
  ];

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await viewBlogServices.getBlogData(id);
      setBlogData(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(baseURL + mainUrl);
    openSuccessNotification("URL copied to your clipboard");
  };

  const handleShare = (link: string) => {
    link ? window.open(link) : copyUrl();
  };

  const getHeader = () => {
    return (
      <Box sx={classes.profileBackImg}>
        <Container maxWidth="lg">
          <Stack alignItems={"center"}>
            <Typography sx={classes.blogHeadline}>
              {blogData.headline}
            </Typography>
            <Grid
              container
              sx={{ zIndex: 2, mt: 5 }}
              justifyContent={"center"}
              textAlign={"center"}
            >
              <Grid item>
                <Stack>
                  <Typography
                    sx={{
                      ...classes.blogDetails,
                      fontSize: getRelativeFontSize(1),
                    }}
                  >
                    Author
                  </Typography>
                  <Typography sx={classes.blogDetails}>
                    {blogData.author}
                  </Typography>
                </Stack>
              </Grid>
              <Divider
                sx={{ border: "2px solid #FFFFFF", mx: 5 }}
                orientation="vertical"
                flexItem
              />
              <Grid item>
                <Stack>
                  <Typography
                    sx={{
                      ...classes.blogDetails,
                      fontSize: getRelativeFontSize(1),
                    }}
                  >
                    Published on
                  </Typography>
                  <Typography sx={classes.blogDetails}>
                    {blogData.date}
                  </Typography>
                </Stack>
              </Grid>
              <Divider
                sx={{ border: "2px solid #FFFFFF", mx: 5 }}
                orientation="vertical"
                flexItem
              />
              <Grid item>
                <Stack>
                  <Typography
                    sx={{
                      ...classes.blogDetails,
                      fontSize: getRelativeFontSize(1),
                    }}
                  >
                    Source
                  </Typography>
                  <Typography sx={classes.blogDetails}>
                    {blogData.source}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    );
  };

  const getBlog = () => {
    return (
      <Box sx={classes.blogBox}>
        <Stack spacing={2}>
          <Typography sx={classes.blogTitle}>{blogData.title}</Typography>
          <img src={blogData.image} alt="cover" style={classes.blogImage} />

          <Grid container>
            {shareButtons.map((button: any) => {
              return (
                <Grid
                  item
                  sx={{ cursor: "pointer", pr: 5 }}
                  onClick={() => handleShare(button.link)}
                >
                  <CustomIcon icon={<button.icon fontSize="large" />} />
                </Grid>
              );
            })}
          </Grid>
          <Box sx={classes.blogContentBox}>
            <div dangerouslySetInnerHTML={{ __html: blogData.content }} />
          </Box>
        </Stack>
      </Box>
    );
  };

  const getViewBlog = () => {
    return (
      <Box>
        {getHeader()}
        {getBlog()}
        <CustomLoader isLoading={isLoading} />
      </Box>
    );
  };

  return getViewBlog();
};

export default ViewBlog;
