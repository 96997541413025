import {
  boldFont,
  getRelativeFontSize,
  regularFont,
} from "../../../../utils/styles";

const childpageStyle = {
  mainBox: {
    maxWidth: "900px",
    width: "100%",
    margin: "0 auto",
    p: 2,
  },
  headerText: {
    ...boldFont,
    fontSize: getRelativeFontSize(20),
    pb: 2,
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(0),
    color: "#a9a9a9",
    pb: 2,
  },
  blogImage: {
    height: "400px",
    width: "900px",
    cursor: "pointer",
  },
  blogSummaryBox: {
    margin: "20px 0",
    marginRight: "0",
    boxShadow: "0 0 3px 1px #bbb",
    borderTop: "9px solid black",
    p: 2,
  },
  blogSummaryTitle: {
    ...boldFont,
    color: "#000000",
    fontSize: getRelativeFontSize(8),
  },
} as const;

export default childpageStyle;
