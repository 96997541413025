import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../utils/styles";

const appHeaderStyles = {
  appHeaderTitle: {
    ...mediumFont,
  },
  menuMobile: {
    width: "100vw",
  },
  headerText: {
    ...regularFont,
    fontSize: getRelativeFontSize(6),
    cursor: "pointer",
    "&:hover": {
      color: "#f15e22",
      transition: "all 0.5s ease",
      textShadow: "0 0 15px #f15e22",
    },
    [theme.breakpoints.down(1487)]: {
      fontSize: getRelativeFontSize(2),
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(6),
    },
  },
  selectedHeaderText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(6),
    color: "#f15e22",
    cursor: "pointer",
  },
  addressText: {
    ...regularFont,
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#FFFFFF",
    [theme.breakpoints.down(1350)]: {
      fontSize: "13px",
    },
  },
  iconList: {
    position: "relative",
    height: "50px",
    width: "50px",
    display: "-webkit-box",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "5px solid #FFFFFF",
    borderRadius: "50%",
    overflow: "hidden",
    transition: "all 500ms ease",
    zIndex: "1",
    cursor: "pointer",
    marginRight: "5px",
    "&:after": {
      backgroundColor: "#f15e22",
      borderRadius: "30px",
      content: `''`,
      display: "block",
      height: "100%",
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      transform: "translate(0, -100%) ",
      transformOrigin: "bottom",
      transition: "all .5s ease-in-out",
      willChange: "transform",
      zIndex: -1,
    },
    "&:hover": {
      "& .profileText": {
        transition: "all .5s ease-in-out",
        color: "#FFFFFF",
      },
      "&:after": {
        transform: "translate(0, 0)",
      },
    },
    [theme.breakpoints.down(1350)]: {
      height: "40px",
      width: "40px",
    },
  },
  profileText: {
    ...regularFont,
    fontWeight: "700",
    fontSize: "25px",
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      transition: "all .5s ease-in-out",
      color: "#f4875a",
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: "20px",
    },
  },
  menuItemText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    color: "#000000",
    pl: 2,
  },
} as const;

export default appHeaderStyles;
