import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import { OTPAPI, ResendOTPApi } from "../landingPageService";
import urls from "../../../global/constants/UrlConstants";
import notifiers from "../../../global/constants/NotificationConstants";
import history from "../../../utils/history";
import {
  CustomButton,
  CustomIcon,
  CustomInput,
} from "../../../global/components";
import { OTPForm, OTPValidation } from "./LoginTypesAndValidation";
import OTPVerificationStyles from "./OTPVerification.styles";
import backIcon from "../../../assets/icons/BackArrow.svg";

interface CustomProps {
  otpData?: any;
}

const OTPVerification = (props: CustomProps) => {
  // useTitle(strings.OTPVerificationTitle);
  const classes = OTPVerificationStyles;
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(OTPForm);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 60000);
  }, []);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleResendOTP = async () => {
    try {
      const response = await ResendOTPApi({
        email: props.otpData.email,
      });
      openSuccessNotification(response.message);
    } catch (error: any) {
      setIsLoading(true);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleOTPVerification = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        const response = await OTPAPI(
          {
            otp: formFields.OTP.value,
          },
          props.otpData.token
        );
        setIsLoading(false);
        openSuccessNotification(response.message);
        history.push(urls.loginViewPath);
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOTPVerification();
    }
  };

  const handleValidation = () => {
    const { isValid, errors } = OTPValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getOTPVerificationScreen = () => {
    return (
      <Box sx={classes.getOTPVerificationScreen}>
        <Box sx={classes.innerGetOTPVerificationBox}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{ cursor: "pointer", pr: 2 }}
              onClick={() => history.push(urls.homeViewPath)}
            >
              <CustomIcon icon={<img src={backIcon} alt="Module Not Found..." />} />
            </Box>
            <Typography sx={classes.getHeading}>Verify OTP</Typography>
          </Box>
          <Box sx={classes.label} pb={1}>
            <Typography sx={classes.labelText}>OTP</Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter OTP"
            id="OTP"
            type="OTP"
            name="OTP"
            value={formFields.OTP.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={!isTruthy(formFields.OTP.value) && formFields.OTP.error}
          />
          <Box marginTop={"50px"} width={"100%"}>
            <CustomButton
              label="Submit"
              onClick={handleOTPVerification}
              disabled={isLoading}
              loading={isLoading}
              customClasses={classes.signBtn}
            />
          </Box>
          <Box pt={2} textAlign={"center"}>
            <Button
              sx={{
                ...classes.labelText,
                color: "#f15e22",
                cursor: "pointer",
                fontSize: "18px",
                "&:hover": {
                  background: "#FFFFFF",
                },
              }}
              onClick={handleResendOTP}
              disabled={!visible}
            >
              Resend OTP
            </Button>
          </Box>
          <Box sx={classes.footer}>
            <Typography sx={classes.footerTypo}>
              &copy; {getYear()} SoftSages Technology. All Rights Reserved
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
      }}
      marginTop={{ xs: "50px", md: "0" }}
    >
      {getOTPVerificationScreen()}
    </Box>
  );
};

export default OTPVerification;
