import {
  centerItemFlex,
  customButtonStyle,
  disabledBackgroundColor,
  mediumFont,
} from "../../../utils/styles";

const customButtonStyles = {
  btnStyle: {
    ...customButtonStyle,
    ...mediumFont,
    width: "100%",
    padding: "10px 20px",
    ...centerItemFlex,
    "&:disabled": {
      background: "#FFFFFF",
      border: "1px solid",
      boxShadow: "0px 3px 17px rgba(0, 0, 0, 0.07)",
      borderRadius: "14px",
      color: "#f15e22",
      "&:hover": {
        background: "#FFFFFF",
      },
    },
  },
  glydeGif: {
    width: "15px",
    height: "15px",
  },
  loadingStyle: {
    color: "#000000",
    width: "25px !important",
    height: "25px !important",
  },
  disabled: {
    color: disabledBackgroundColor,
  },
};

export default customButtonStyles;
