export class StringConstants {
  PROD = false;

  //contentTypes
  applicationJSON = { "Content-Type": "application/json" };

  notification = "notification";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";
  autoHideDuration = 600 * 1000; //in milliseconds

  SEARCH_TIME_OUT = 350;

  //Landing Page
  LOGIN = "login";
  REGISTER = "register";
  FORGOT_PASSWORD = "forgot-password";
  OTP = "otp";
  RESET_PASSWORD = "changepwd";

  //Email validation Regex
  regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  //Phone Number Regex
  phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // role

  SUPER_ADMIN = "Super Admin";
  ADMIN = "Admin";
  USER = "User";

  // authManager
  HOME = "Home";
  TOPICS = "Topics";
  BLOG = "Blog";
  ADDBLOG = "Add Blog";
  VIEWBLOG = "View Blog";
  PROFILE = "Profile";
  PAGENOTFOUND = "Page Not Found";

  jpeg = ".jpeg";
  JPEG = ".JPEG";
  JPG = "JPG";
  jpg = ".jpg";
  png = ".png";
  PNG = ".PNG";
  gif = ".gif";
  bmp = ".bmp";
  tiff = ".tiff";

  view = "View";
  download = "Download";
}

let strings = new StringConstants();
export default strings;
