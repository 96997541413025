import { useState } from "react";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import { loginAPI } from "../landingPageService";
import urls from "../../../global/constants/UrlConstants";
import notifiers from "../../../global/constants/NotificationConstants";
import { useAppDispatch } from "../../../utils/hooks";
import loginStyles from "./Login.styles";
import { loginAction } from "../../../redux/authSlice";
import history from "../../../utils/history";
import {
  CustomButton,
  CustomIcon,
  CustomInput,
} from "../../../global/components";
import { loginForm, loginValidation } from "./LoginTypesAndValidation";
import strings from "../../../global/constants/StringConstants";
import hidePasswordIcon from "../../../assets/images/Hide.svg";
import showPasswordIcon from "../../../assets/images/Show.svg";
import backIcon from "../../../assets/icons/BackArrow.svg";

const Login = () => {
  // useTitle(strings.LoginTitle);
  const classes = loginStyles;
  const emailRegex = strings.regex;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(loginForm);
  const [showPassword, setShowPassword] = useState(false);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleLogin = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        const email = formFields.email.value.toLowerCase();
        const password = formFields.password.value;
        const user = await loginAPI({ email, password });
        dispatch(
          loginAction({
            email,
            authenticated: true,
            accessToken: user.access,
            firstName: user.firstName,
            lastName: user.lastName,
            refreshToken: user.refresh,
          })
        );
        setIsLoading(false);
        openSuccessNotification(user.message);
        history.push(urls.homeViewPath);
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.LOGIN_ERROR
      );
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = loginValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getLoginScreen = () => {
    return (
      <Box sx={classes.getLoginScreen}>
        <Box sx={classes.innerGetLoginBox}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{ cursor: "pointer", pr: 2 }}
              onClick={() => history.push(urls.homeViewPath)}
            >
              <CustomIcon
                icon={<img src={backIcon} alt="Module Not Found..." />}
              />
            </Box>
            <Typography sx={classes.getHeading}>Log In</Typography>
          </Box>

          <Box sx={classes.label} pb={1}>
            <Typography sx={classes.labelText} className="loginValidation">
              Email
            </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter Email Address"
            id="email"
            type="email"
            name="email"
            value={formFields.email.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
          <Box sx={classes.label} borderColor="red" pb={1}>
            <Typography sx={classes.labelText}>Password </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            sx={classes.textRadious}
            placeHolder="••••••••"
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formFields.password.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={
              !isTruthy(formFields.password.value) && formFields.password.error
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <Box
                      component="img"
                      src={showPassword ? showPasswordIcon : hidePasswordIcon}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box pt={1}>
            <Typography
              sx={{ ...classes.labelText, cursor: "pointer", textAlign: "end" }}
              onClick={() => history.push(urls.forgotPasswordViewPath)}
            >
              Forgot password?
            </Typography>
          </Box>
          <Box marginTop={"50px"} width={"100%"}>
            <CustomButton
              label="Login"
              onClick={handleLogin}
              disabled={isLoading}
              loading={isLoading}
              customClasses={classes.signBtn}
            />
          </Box>
          <Box pt={2} display={"flex"}>
            <Typography sx={classes.labelText}>
              New around here? Create an account
            </Typography>
            <Typography
              sx={{ ...classes.labelText, color: "#f15e22", cursor: "pointer" }}
              onClick={() => history.push(urls.registerViewPath)}
            >
              Join Panacea Insights
            </Typography>
          </Box>
          <Box sx={classes.footer}>
            <Typography sx={classes.footerTypo}>
              Panacea Insights &copy; {getYear()} Panacea Project is a
              Corporation Classified under (IRC) Section 501 (C)(3).
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
      }}
      marginTop={{ xs: "50px", md: "0" }}
    >
      {getLoginScreen()}
    </Box>
  );
};

export default Login;
