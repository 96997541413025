import {
  Box,
  Container,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import addBlogStyle from "./AddBlog.styles";
import { CustomButton, CustomInput } from "../../../global/components";
import { useState } from "react";
import { DropzoneAreaBase } from "react-mui-dropzone";
import strings from "../../../global/constants/StringConstants";
import { ReactComponent as UploadIcon } from "../../../assets/icons/UploadIcon.svg";
import { addBlogForm, generateHeadlineValidation } from "./AddBlogValidation";
import * as addBlogService from "./AddBlog.service";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import { Editor } from "@tinymce/tinymce-react";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";

const AddBlog = () => {
  const classes = addBlogStyle;
  const [blogForm, setBlogForm] = useState(addBlogForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const topics = [
    { name: "Hydroponics" },
    { name: "Technology" },
    { name: "Innovation" },
    { name: "Wellness" },
    { name: "Finance" },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setBlogForm({
      ...blogForm,
      key: {
        ...blogForm.key,
        value: event.target.value,
      },
    });
  };

  const handleTextFields = (event: React.ChangeEvent<any>) => {
    setBlogForm({
      ...blogForm,
      [event.target.name]: {
        ...blogForm[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleOnDropZoneFile = async (event: React.ChangeEvent<any> | any) => {
    try {
      // console.log(event[0]);
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", event[0].file);
      const response = await addBlogService.uploadImage(formData);
      setBlogForm({
        ...blogForm,
        image: {
          ...blogForm.image,
          value: response.image,
          path: response.imagePath,
        },
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleSummary = async () => {
    try {
      if (handleValidation(false)) {
        setIsLoading(true);
        const response = await addBlogService.generateSummary(
          blogForm.content.value
        );
        setBlogForm({
          ...blogForm,
          headline: { value: response.headline, error: "" },
          summary: { value: response.summary, error: "" },
        });
        setIsVisible(true);
        setIsRequired(true);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleSubmit = async () => {
    try {
      if (handleValidation(true)) {
        setIsLoading(true);
        const response = await addBlogService.addBlog(blogForm);
        openSuccessNotification(response.message);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleValidation = (isSubmit: boolean) => {
    const { isValid, errors } = generateHeadlineValidation(blogForm, isSubmit);
    setBlogForm({ ...errors });
    return isValid;
  };

  const handleBlogContent = (event: any) => {
    setBlogForm({
      ...blogForm,
      content: {
        ...blogForm.content,
        value: event.target.getContent(),
      },
    });
  };

  const getHeader = () => {
    return (
      <Grid container justifyContent={"center"}>
        <Box pt={5} borderBottom={"double"}>
          <Typography sx={classes.header}>Write Your Blog</Typography>
        </Box>
      </Grid>
    );
  };

  const getBlogContentTextfield = () => {
    return (
      <>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Box mb={1}>
            <InputLabel required={isRequired} sx={classes.nameField}>
              Topic Name
            </InputLabel>
          </Box>
          <Select
            sx={classes.selectKeys}
            value={blogForm.key.value}
            onChange={(event: SelectChangeEvent) => {
              handleChange(event);
            }}
            {...(!isTruthy(blogForm.key.value) &&
              blogForm.key.error && {
                error: true,
                helperText: blogForm.key.error,
              })}
          >
            {topics.map((topic) => {
              return <MenuItem value={topic.name}>{topic.name}</MenuItem>;
            })}
          </Select>
          {!isTruthy(blogForm.key.value) && blogForm.key.error && (
            <FormHelperText error sx={classes.errorStyling}>
              {blogForm.key.error}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <CustomInput
            required={isRequired}
            label={"Blog Title"}
            name="title"
            value={blogForm.title.value}
            onChange={handleTextFields}
            error={!isTruthy(blogForm.title.value) && blogForm.title.error}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {/* <CustomInput
            required
            multiline
            rows={20}
            label={"Blog Content"}
            name="content"
            value={blogForm.content.value}
            onChange={handleTextFields}
            error={!isTruthy(blogForm.content.value) && blogForm.content.error}
          /> */}
          <Editor
            apiKey="7qoawbyn9ty4y1jrtaj6cor98lrl0sbp2p90wgtbfa60tso1"
            initialValue=""
            init={{
              branding: false,
              height: 600,
              menubar: false,
              toolbar:
                "formatselect | bold italic underline | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
              image_advtab: false,
            }}
            onChange={handleBlogContent}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={classes.customDropZone}>
            <Box mb={1}>
              <InputLabel sx={classes.nameField}>Blog Image</InputLabel>
            </Box>
            <DropzoneAreaBase
              fileObjects={[]}
              dropzoneText={"Choose File"}
              onAdd={(event: React.ChangeEvent<any> | any) => {
                handleOnDropZoneFile(event);
              }}
              acceptedFiles={[
                strings.jpeg,
                strings.jpg,
                strings.png,
                strings.gif,
                strings.bmp,
                strings.tiff,
              ]}
              Icon={UploadIcon}
              filesLimit={1}
              maxFileSize={Infinity}
              showAlerts={false}
            />
          </Box>
          <Typography sx={classes.headerTextField}>
            {blogForm.image.value}
          </Typography>
        </Grid>
        {!isVisible && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display={"flex"}
            justifyContent={"center"}
          >
            <CustomButton
              onClick={handleSummary}
              customClasses={{ maxWidth: "350px" }}
              label={"Generate Headline & Summary"}
              disabled={blogForm.content.value ? false : true}
            />
          </Grid>
        )}
      </>
    );
  };

  const getSummaryTextfield = () => {
    return (
      <>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CustomInput
            required
            multiline
            rows={3}
            label={"Blog Headline"}
            name="headline"
            value={blogForm.headline.value}
            onChange={handleTextFields}
            error={
              !isTruthy(blogForm.headline.value) && blogForm.headline.error
            }
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CustomInput
            required
            multiline
            rows={8}
            label={"Blog Summary"}
            name="summary"
            value={blogForm.summary.value}
            onChange={handleTextFields}
            error={!isTruthy(blogForm.summary.value) && blogForm.summary.error}
          />
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
        >
          <CustomButton
            onClick={handleSubmit}
            customClasses={{ maxWidth: "350px" }}
            label={"Submit"}
          />
        </Grid>
      </>
    );
  };

  const getAddBlog = () => {
    return (
      <Container maxWidth="lg">
        {getHeader()}
        <Grid container py={4} spacing={4}>
          {getBlogContentTextfield()}
          {isVisible && getSummaryTextfield()}
        </Grid>
        <CustomLoader isLoading={isLoading} />
      </Container>
    );
  };
  return getAddBlog();
};

export default AddBlog;
