import urls from "../../../global/constants/UrlConstants";
import { getNoAuthCallParams, makeCall } from "../../../utils/service";

export async function getBlogData(id: string) {
  const url = `${urls.GET_BLOG_DATA}?blog_id=${id}`;
  try {
    const callParams = getNoAuthCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}
