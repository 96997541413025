import {
  boldFont,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  pureWhiteColor,
  regularFont,
  theme,
} from "../../../utils/styles";

const addBlogStyle = {
  header: {
    color: "#2e2e2e",
    fontSize: "50px",
    // fontFamily: "Oxygen", sans-serif,
    marginBottom: "25px",
  },
  selectKeys: {
    width: "100%",
    borderRadius: "14px",
    background: pureWhiteColor,
    "& .MuiInputBase-input": {
      borderRadius: "14px",
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "14px",
      // border: "1px solid #E7E7E7",
      "&.Mui-focused fieldset": {
        borderColor: "#000000",
      },
    },
  },
  nameField: {
    ...mediumFont,
    color: "#212121",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
  headerTextField: {
    ...boldFont,
    fontSize: getRelativeFontSize(),
    color: "#000000",
  },
  customDropZone: {
    // border: "1px dashed #000000",
    "& .MuiDropzoneArea-root": {
      width: "100%",
      minHeight: "30px",
      border: "1px dashed #000000",
      background: "#dfdfdf",
      borderRadius: "14px",
    },

    "& .MuiDropzoneArea-textContainer": {
      width: "100%",
      display: "flex",
      flexDirection: "column-reverse",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      padding: "20px 0",
      [theme.breakpoints.down("md")]: {
        padding: "10px 0",
      },
    },
    "& .MuiDropzoneArea-icon": {
      width: "35px",
      height: "35px",
    },
    "& .MuiTypography-root": {
      fontSize: "0.875rem",
      overflowWrap: "break-word",
      width: "50%",
      textAlign: "center",
      marginLeft: "25%",
      color: "#000000",
    },
    "& .MuiDropzoneArea-text": {
      color: "#000000",
      ...regularFont,
      margin: "10px auto",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        margin: "2px auto",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
  errorStyling: {
    paddingLeft: "10px",
  },
} as const;

export default addBlogStyle;
