import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import profileStyle from "./Profile.styles";
import { useAppSelector } from "../../utils/hooks";
import { selectUserEmail, selectUserName } from "../../redux/authSlice";
import BlogList from "../Topics/Components/BlogList/BlogList";
import { useState, useEffect } from "react";
import { isTruthy, openErrorNotification } from "../../helpers/methods";
import notifiers from "../../global/constants/NotificationConstants";
import * as profileServices from "./Profile.service";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import MyLikedBlogs from "./components/MyLikedBlogs";
import MyBlogs from "./components/MyBlogs";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 30px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "30px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const Profile = () => {
  const classes = profileStyle;
  const userName = useAppSelector(selectUserName);
  const userEmail = useAppSelector(selectUserEmail);
  const [count, setCount] = useState({
    myBlogs: 0,
    likedBlogs: 0,
  });
  const [isLiked, setIsLiked] = useState<boolean>(false);

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      const response = await profileServices.getProfileDetails();
      setCount({
        myBlogs: response.myBlogs,
        likedBlogs: response.myLikedBlogs,
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const getUserDetails = () => {
    return (
      <Box sx={classes.profileBackImg}>
        <Stack alignItems={"center"}>
          <Box sx={classes.iconList}>
            <Typography className="profileText" sx={classes.profileText}>
              {userName.charAt(0).toUpperCase()}
            </Typography>
          </Box>
          <Typography sx={classes.userName}>{userName}</Typography>
          <Typography sx={classes.userEmail}>{userEmail}</Typography>

          <Grid
            container
            sx={{ zIndex: 2, mt: "auto" }}
            spacing={6}
            justifyContent={"center"}
            textAlign={"center"}
          >
            <Grid
              item
              sx={{ cursor: "pointer" }}
              onClick={() => setIsLiked(false)}
            >
              <Typography sx={classes.userName}>{count.myBlogs}</Typography>
              <Typography sx={classes.userEmail}>My Blogs</Typography>
            </Grid>
            <Grid
              item
              sx={{ cursor: "pointer" }}
              onClick={() => setIsLiked(true)}
            >
              <Typography sx={classes.userName}>{count.likedBlogs}</Typography>
              <Typography sx={classes.userEmail}>Liked Blogs</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    );
  };

  const getHeader = () => {
    return (
      <Grid container justifyContent={"center"} pb={3}>
        <Box pt={5} borderBottom={"double"}>
          <Typography sx={classes.header}>
            {isLiked ? "Liked Blogs" : "My Blogs"}
          </Typography>
        </Box>
      </Grid>
    );
  };

  const getMyBlogs = () => {
    return isLiked ? <MyLikedBlogs /> : <MyBlogs />;
  };

  const getProfile = () => {
    return (
      <Box>
        {getUserDetails()}
        {getHeader()}
        {getMyBlogs()}
      </Box>
    );
  };

  return getProfile();
};

export default Profile;
