import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import BlogList from "../../Topics/Components/BlogList/BlogList";
import profileStyle from "../Profile.styles";
import { useEffect, useState } from "react";
import { isTruthy, openErrorNotification } from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import * as profileServices from "../Profile.service";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";
import NoBlogsImage from "../../../assets/images/NoBlogsImage.svg";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 30px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "30px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const MyLikedBlogs = () => {
  const classes = profileStyle;
  const [likedBlogs, setLikedBlogs] = useState({
    blogs: [],
    count: 0,
    totalPages: 0,
  });
  const [page, setPage] = useState<number>(1);
  const [dataPerPage, setDataPerPage] = useState<number>(12);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getMyLikedBlogs(page, dataPerPage);
  }, [page, dataPerPage]);

  const getMyLikedBlogs = async (page: number, dataPerPage: number) => {
    try {
      setIsLoading(true);
      const likedBlogs = await profileServices.getMyLikedBlogs(
        page,
        dataPerPage
      );
      setLikedBlogs({
        blogs: likedBlogs.blogs,
        count: likedBlogs.totalData,
        totalPages: likedBlogs.totalPages,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleChange = (event: any) => {
    setDataPerPage(event.target.value);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} py={5} justifyContent={"center"}>
        <BlogList blogs={likedBlogs.blogs} is_latest={false} />
        {likedBlogs.count !== 0 ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={classes.paginationWrapper}
          >
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel>Blogs per page</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={dataPerPage}
                onChange={handleChange}
                input={<BootstrapInput />}
                defaultValue={12}
              >
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              sx={classes.pagination}
              showFirstButton
              showLastButton
              count={likedBlogs.totalPages}
              color="primary"
              page={page}
              onChange={handleChangePage}
            />
          </Grid>
        ) : (
          <img height={"250px"} src={NoBlogsImage} />
        )}
      </Grid>
      <CustomLoader isLoading={isLoading} />
    </Container>
  );
};

export default MyLikedBlogs;
