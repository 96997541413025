import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
} from "../../../utils/styles";

const appFooterStyles = {
  footer: {
    height: "300px",
    background: "#000000",
  },
  footerTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(),
  },
  footerSubTypo: {
    color: "#FFFFFF",
    cursor: "pointer",
    ...regularFont,
    "&:hover": {
      color: "#f15e22",
      transition: "all 0.5s ease",
      textShadow: "0 0 15px #f15e22",
    },
  },
  iconWrapper: {
    paddingTop: "7px",
    paddingLeft: "8px",
  },
} as const;

export default appFooterStyles;
