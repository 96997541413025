export const blogDetails = {
  author: "",
  date: "",
  headline: "",
  id: "",
  image: "",
  key: "",
  parenturl: "",
  source: "",
  summary: "",
  title: "",
  url: "",
  content: "",
};
