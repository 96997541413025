import { Box, Container, Grid, Typography } from "@mui/material";
import BlogList from "../Topics/Components/BlogList/BlogList";
import { useEffect, useState } from "react";
import * as homeServices from "./Home.service";
import { isTruthy, openErrorNotification } from "../../helpers/methods";
import notifiers from "../../global/constants/NotificationConstants";
import homeStyles from "./Home.styles";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { store } from "../../utils/store";

const Home = () => {
  const classes = homeStyles;
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [trendingBlogs, setTrendingBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticate = store.getState().auth.authenticated;

  useEffect(() => {
    document.title = "Panacea Insights";
    getData();
  }, [isAuthenticate]);

  const getData = async () => {
    try {
      setIsLoading(true);
      setTrendingBlogs([]);
      setRecentBlogs([]);
      const response = await homeServices.getHomeBlogData(isAuthenticate);
      setTrendingBlogs(response.trending);
      setRecentBlogs(response.latesBlogs);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent={"center"}>
        <Box pt={5} borderBottom={"double"}>
          <Typography sx={classes.header}>Trending</Typography>
        </Box>
      </Grid>
      <Grid container spacing={3} pt={5}>
        <BlogList blogs={trendingBlogs} is_latest={true} />
      </Grid>
      <Grid container justifyContent={"center"}>
        <Box pt={5} borderBottom={"double"}>
          <Typography sx={classes.header}>Most Recent</Typography>
        </Box>
      </Grid>
      <Grid container spacing={3} py={5}>
        <BlogList blogs={recentBlogs} is_latest={false} />
      </Grid>
      <CustomLoader isLoading={isLoading} />
    </Container>
  );
};

export default Home;
