import { useState } from "react";
import { Box, FormHelperText, Typography } from "@mui/material";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import { forgotPasswordAPI } from "../landingPageService";
import urls from "../../../global/constants/UrlConstants";
import notifiers from "../../../global/constants/NotificationConstants";
import ForgotPasswordStyles from "./ForgotPassword.styles";
import history from "../../../utils/history";
import {
  CustomButton,
  CustomIcon,
  CustomInput,
} from "../../../global/components";
import {
  forgotPasswordForm,
  forgotPasswordValidation,
} from "./LoginTypesAndValidation";
import strings from "../../../global/constants/StringConstants";
import backIcon from "../../../assets/icons/BackArrow.svg";

const ForgotPassword = () => {
  // useTitle(strings.ForgotPasswordTitle);
  const classes = ForgotPasswordStyles;
  const emailRegex = strings.regex;
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(forgotPasswordForm);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleForgotPassword = async () => {
    try {
      if (handleValidation()) {
        const userData = {
          email: formFields.email.value,
        };
        setIsLoading(true);
        const response = await forgotPasswordAPI(userData);
        setIsLoading(false);
        openSuccessNotification(response.message);
        history.push(urls.loginViewPath);
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleForgotPassword();
    }
  };

  const handleValidation = () => {
    const { isValid, errors } = forgotPasswordValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getForgotPasswordScreen = () => {
    return (
      <Box sx={classes.getForgotPasswordScreen}>
        <Box sx={classes.innerGetForgotPasswordBox}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{ cursor: "pointer", pr: 2 }}
              onClick={() => history.push(urls.homeViewPath)}
            >
              <CustomIcon icon={<img src={backIcon} alt="Module Not Found..." />} />
            </Box>
            <Typography sx={classes.getHeading}>Forgot Password</Typography>
          </Box>
          <Box sx={classes.label} pb={1}>
            <Typography sx={classes.labelText}>Email</Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter Email Address"
            id="email"
            type="email"
            name="email"
            value={formFields.email.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
          <Box marginTop={"50px"} width={"100%"}>
            <CustomButton
              label="Submit"
              onClick={handleForgotPassword}
              disabled={isLoading}
              loading={isLoading}
              customClasses={classes.signBtn}
            />
          </Box>
          <Box pt={2}>
            <Typography
              sx={{
                ...classes.labelText,
                color: "#f15e22",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => history.push(urls.loginViewPath)}
            >
              Back to Login
            </Typography>
          </Box>
          <Box sx={classes.footer}>
            <Typography sx={classes.footerTypo}>
              &copy; {getYear()} SoftSages Technology. All Rights Reserved
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
      }}
      marginTop={{ xs: "50px", md: "0" }}
    >
      {getForgotPasswordScreen()}
    </Box>
  );
};

export default ForgotPassword;
