import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../utils/styles";
import blogBackImg from "../../../assets/images/blogBackImg.jpg";

const viewBlogStyle = {
  header: {
    color: "#2e2e2e",
    fontSize: "50px",
    marginBottom: "25px",
  },
  profileBackImg: {
    position: "relative",
    backgroundImage: "url(" + blogBackImg + ")",
    height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center",
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      height: "40vh",
    },

    "&:before": {
      zIndex: 1,
      position: "absolute",
      backgroundColor: "#000000ba",
      content: `""`,
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
  blogHeadline: {
    ...mediumFont,
    fontSize: getRelativeFontSize(15),
    color: "#FFFFFF",
    zIndex: 2,
    textAlign: "center",
  },
  blogDetails: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    color: "#FFFFFF",
    zIndex: 2,
  },
  blogTitle: {
    ...mediumFont,
    fontSize: getRelativeFontSize(10),
    color: "#000000",
  },
  blogImage: {
    height: "400px",
    width: "900px",
    cursor: "pointer",
  },
  blogBox: {
    maxWidth: "900px",
    width: "100%",
    margin: "0 auto",
    p: 2,
  },
  blogContentBox: {
    marginTop: "20px",
    marginRight: "0",
    boxShadow: "0 0 3px 1px #bbb",
    borderTop: "9px solid black",
    p: 2,
  },
  blogContent: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#000000",
    p: 2,
  },
} as const;

export default viewBlogStyle;
