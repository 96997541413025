import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import appFooterStyles from "./AppFooter.styles";
import { regularFont } from "../../../utils/styles";
import { CustomIcon } from "../../../global/components";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const AppFooter = () => {
  const classes = appFooterStyles;

  const getSocialMediaIcons = () => {
    return (
      <Box className=" wrapper" pt={5}>
        <Grid container className="iconWrapper" justifyContent={"center"}>
          <Grid item className="iconList facebook">
            <Box
              className="icon facebookIcon"
              sx={classes.iconWrapper}
              onClick={() =>
                window.open("https://www.facebook.com/OfficialPanaceaProject/")
              }
            >
              <CustomIcon icon={<FacebookOutlinedIcon fontSize="large" />} />
            </Box>
          </Grid>
          <Grid item className="iconList twitter">
            <Box
              className="icon twitterIcon"
              sx={classes.iconWrapper}
              onClick={() => window.open("https://twitter.com/PanaceaProject1")}
            >
              <CustomIcon icon={<TwitterIcon fontSize="large" />} />
            </Box>
          </Grid>
          <Grid
            item
            className="iconList instagram"
            sx={classes.iconWrapper}
            onClick={() =>
              window.open("https://www.instagram.com/OfficialPanaceaProject/")
            }
          >
            <Box className="icon instagramIcon">
              <CustomIcon icon={<InstagramIcon fontSize="large" />} />
            </Box>
          </Grid>
          <Grid
            item
            className="iconList twitter"
            sx={classes.iconWrapper}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/panaceaproject?trk=similar-pages&originalSubdomain=be"
              )
            }
          >
            <Box className="icon twitterIcon">
              <CustomIcon icon={<LinkedInIcon fontSize="large" />} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getURLs = () => {
    return (
      <Box pt={5}>
        <Grid container justifyContent={"center"} spacing={6}>
          <Grid item>
            <Typography
              sx={classes.footerSubTypo}
              onClick={() =>
                window.open("https://www.panaceaproject.com/contact")
              }
            >
              Contact us
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={classes.footerSubTypo}
              onClick={() =>
                window.open("https://www.panaceaproject.com/terms-of-use")
              }
            >
              Terms of Use
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={classes.footerSubTypo}
              onClick={() =>
                window.open("https://www.panaceaproject.com/privacy-policy")
              }
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getCopyrights = () => {
    return (
      <Box pt={5}>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            sx={{
              color: "#FFFFFF",
              ...regularFont,
            }}
          >
            Panacea Insights © 2023 Panacea Project is a Corporation Classified
            under (IRC) Section 501 (C)(3).
          </Typography>
        </Box>
      </Box>
    );
  };

  const getAppFooter = () => {
    return (
      <Box sx={classes.footer}>
        {getSocialMediaIcons()}
        {getURLs()}
        {getCopyrights()}
      </Box>
    );
  };

  return getAppFooter();
};

export default AppFooter;
