import {
  inputLabelRequiredColor,
  mediumFont,
  pureWhiteColor,
} from "../../../utils/styles";

const customInputStyles = {
  textField: {
    width: "100%",
    borderRadius: "14px",
    background: pureWhiteColor,
    "& .MuiInputBase-input": {
      borderRadius: "14px",
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "14px",
      // border: "1px solid #E7E7E7",
      "&.Mui-focused fieldset": {
        borderColor: "#000000",
      },
    },
  },
  nameField: {
    ...mediumFont,
    color: "#212121",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
} as const;

export default customInputStyles;
