import urls from "../../../global/constants/UrlConstants";
import {
  getCallParams,
  getFileCallParams,
  makeCall,
} from "../../../utils/service";

export const generateSummary = async (content: string) => {
  const body = {
    blog: content,
  };
  try {
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.HEADLINE_SUMMARY, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const uploadImage = async (imageData: any) => {
  try {
    const callParams = await getFileCallParams(imageData);
    const response: any = await makeCall(urls.UPLOAD_IMAGE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const addBlog = async (blogForm: any) => {
  const body = {
    key: blogForm.key.value,
    title: blogForm.title.value,
    headline: blogForm.headline.value,
    summary: blogForm.summary.value,
    content: blogForm.content.value,
    image: blogForm.image.path,
  };
  try {
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.ADD_BLOG, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
