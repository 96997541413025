import {
  mediumFont,
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  pinkDarkColor,
  theme,
  pureWhiteColor,
} from "../../../utils/styles";

const registerStyles = {
  select: {
    borderRadius: "20px",
    border: "1px solid black",
    width: "100% !important",
  },
  error: {
    borderRadius: "8px",
    outline: "none",
    borderColor: "red !important",
    width: "100% !important",
  },
  label: { display: "flex", height: "20px", marginTop: "15px" },
  labelIcon: { color: "black" },
  labelText: {
    marginLeft: "6px",
    ...mediumFont,
  },
  formCenter: {
    ...centerItemFlex,
  },

  headingCenter: {
    ...mediumFont,
    fontSize: getRelativeFontSize(),
    mt: 2,
    ...centerItemFlex,
  },
  joinMailzzyHeadingCenter: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    mt: 2,
    ...centerItemFlex,
    color: pinkDarkColor,
  },
  textRadios: {
    borderRadius: "100px",
  },
  getHeading: {
    ...mediumFont,
    fontSize: getRelativeFontSize(20),
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
  },
  center: {
    ...mediumFont,
    alignItem: "center",
  },
  getSignUpScreen: {
    display: "grid",
    height: "100%",
    alignItems: "center",
    mt: 15,
    [theme.breakpoints.down("md")]: {
      mt: 10,
    },
  },
  innerGetSignUpBox: { maxWidth: "100%", maxHeight: "100%", padding: 4 },
  forgetPasswordWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  signBtn: {
    width: "100%",
  },
  errorStyling: {
    paddingLeft: "10px",
  },
  footer: {
    bottom: 0,
    alignItems: "center",
    width: "80%",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    textAlign: "center",
    fontSize: getRelativeFontSize(0),
  },
  modalTitle: {
    ...mediumFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  fontText: {
    ...mediumFont,
  },
  footerWrapper: {
    ...centerItemFlex,
    gap: "10px",
    paddingBottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  centerBotton: {
    ...centerItemFlex,
    marginTop: "20px",
  },
  textField: {
    width: "100%",
    borderRadius: "20px",
    background: pureWhiteColor,
    "& .MuiInputBase-input": {
      borderRadius: "20px",
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
      // border: "1px solid #E7E7E7",
      "&.Mui-focused fieldset": {
        borderColor: "#000000",
      },
    },
  },
} as const;

export default registerStyles;
