import { StringConstants } from "./StringConstants";

class UrlConstants extends StringConstants {
  url_dev = "https://api.panaceainsights.com/api";
  // url_dev = "http://3.211.50.139:5002/panacea/api";
  url = this.url_dev;

  timeout = "timeout";

  // view-path
  landingViewPath = "/";
  loginViewPath = "/login";
  registerViewPath = "/register";
  forgotPasswordViewPath = "/forgot-password";
  otpViewPath = "/otp";

  homeViewPath = "/";
  hydroponicsViewPath = "/Hydroponics";
  technologyViewPath = "/Technology";
  innovationViewPath = "/Innovation";
  wellnessViewPath = "/Wellness";
  financeViewPath = "/Finance";
  blogPageViewPath = "/blog";
  searchViewPath = "/search";
  profileViewPath = "/profile";
  addBlogViewPath = "/add-blog";
  viewBlogViewPath = "/view-blog";

  // auth url
  loginApi = `${this.url}/public/login`;
  registerApi = `${this.url}/public/sign-up`;
  forgotPasswordApi = `${this.url}/public/forgot-password`;
  OTPAPI = `${this.url}/auth/validate-otp`;
  resendOTPAPI = `${this.url}/public/resend-otp`;

  //topics url
  GET_DATA = `${this.url}/blogs/get-category`;
  GET_SUMMARY =
    "http://192.168.50.171:5002/panacea/api/multi/document/summarizer";
  GET_BLOG_DATA = `${this.url}/blogs/get-blog`;
  GET_RECOMMENDED_BLOG_DATA = `${this.url}/blogs/get-recommended-blogs`;
  GET_ALL_BLOG = `${this.url}/blogs/all`;
  LIKE_BLOG = `${this.url}/blogs/like-blog`;

  // add blog url
  UPLOAD_IMAGE = `${this.url}/blogs/upload-image`;
  HEADLINE_SUMMARY = `${this.url}/blogs/get-headline-summary`;
  ADD_BLOG = `${this.url}/blogs/add-blog`;

  // profile url
  PROFILE_DETAILS = `${this.url}/blogs/my-profile-details`;
  MY_BLOGS = `${this.url}/blogs/my-blogs`;
  MY_LIKED_BLOGS = `${this.url}/blogs/my-liked-blogs`;
}

let urls = new UrlConstants();
export default urls;
