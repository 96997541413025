import strings from "../../../global/constants/StringConstants";
import {
  LoginFields,
  RegistrationFeild,
  ResetPasswordFields,
} from "./../../../models/interfaces";

export const loginForm = () => {
  return {
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  } as LoginFields;
};

export const loginValidation = (loginUserData: any) => {
  let errors = loginUserData;
  let isValid = true;
  const email = loginUserData.email.value;
  const password = loginUserData.password.value;
  if (!email) {
    errors.email.error = "Please enter your email id";
    isValid = false;
  }
  if (!password) {
    errors.password.error = "Please enter your password";
    isValid = false;
  }
  return { isValid, errors };
};

export const registerFormField = () => {
  return {
    firstName: {
      value: "",
      error: "",
    },
    lastName: {
      value: "",
      error: "",
    },
    contact: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  } as RegistrationFeild;
};

export const registrationValidation = (registerUserValue: any) => {
  let errors = registerUserValue;
  let isValid = true;
  const firstName = registerUserValue.firstName.value;
  const email = registerUserValue.email.value;
  const password = registerUserValue.password.value;
  const lastName = registerUserValue.lastName.value;
  if (!firstName) {
    errors.firstName.error = "Please enter firstName";
    isValid = false;
  }
  if (!email) {
    errors.email.error = "Please enter email";
    isValid = false;
  }
  if (!strings.regex.test(email)) {
    errors.email.error = "Please enter valid email id";
    isValid = false;
  }
  if (!password) {
    errors.password.error = "Please enter your password";
    isValid = false;
  }
  if (!lastName) {
    errors.lastName.error = "Please enter your lastName";
    isValid = false;
  }
  return { isValid, errors };
};

export const resetPasswordForm = () => {
  return {
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  } as ResetPasswordFields;
};

export const resetPasswordValidation = (resetPasswordUserData: any) => {
  let errors = resetPasswordUserData;
  let isValid = true;
  const email = resetPasswordUserData.email.value;
  const password = resetPasswordUserData.password.value;
  if (!email) {
    errors.email.error = "Please enter your email id";
    isValid = false;
  }
  if (!password) {
    errors.password.error = "Please enter your password";
    isValid = false;
  }
  return { isValid, errors };
};

export const forgotPasswordForm = () => {
  return {
    email: {
      value: "",
      error: "",
    },
  } as RegistrationFeild;
};

export const forgotPasswordValidation = (forgotPasswordUserData: any) => {
  let errors = forgotPasswordUserData;
  let isValid = true;
  const email = forgotPasswordUserData.email.value;
  if (!email) {
    errors.email.error = "Please enter your email id";
    isValid = false;
  }
  return { isValid, errors };
};

export const OTPForm = () => {
  return {
    OTP: {
      value: "",
      error: "",
    },
  } as RegistrationFeild;
};

export const OTPValidation = (OTPUserData: any) => {
  let errors = OTPUserData;
  let isValid = true;
  const OTP = OTPUserData.OTP.value;
  if (!OTP) {
    errors.OTP.error = "Please enter your OTP";
    isValid = false;
  }
  return { isValid, errors };
};
