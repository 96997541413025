import urls from "../../global/constants/UrlConstants";
import {
  getNoAuthCallParams,
  getUserTokenCallParams,
  makeCall,
} from "../../utils/service";

export const loginAPI = async (userInput: any) => {
  try {
    const callParams = await getNoAuthCallParams("POST", userInput);
    const response = await makeCall(urls.loginApi, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const registerAPI = async (userInput: any) => {
  try {
    const callParams = await getNoAuthCallParams("POST", userInput);
    const response = await makeCall(urls.registerApi, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const forgotPasswordAPI = async (userInput: any) => {
  try {
    const callParams = await getNoAuthCallParams("POST", userInput);
    const response = await makeCall(urls.forgotPasswordApi, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const OTPAPI = async (userInput: any, token: string) => {
  try {
    const callParams = await getUserTokenCallParams("POST", token, userInput);
    const response = await makeCall(urls.OTPAPI, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const ResendOTPApi = async (userInput: any) => {
  try {
    const callParams = await getNoAuthCallParams("POST", userInput);
    const response = await makeCall(urls.resendOTPAPI, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
