import urls from "../../global/constants/UrlConstants";
import {
  getCallParams,
  getNoAuthCallParams,
  makeCall,
} from "../../utils/service";

export async function getHomeBlogData(isAuthenticate: boolean) {
  try {
    const callParams = !isAuthenticate
      ? getNoAuthCallParams("GET")
      : await getCallParams("GET");
    const response: any = await makeCall(urls.GET_ALL_BLOG, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}
