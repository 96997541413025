import { useState } from "react";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  getFormattedNumbers,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import { registerAPI } from "../landingPageService";
import urls from "../../../global/constants/UrlConstants";
import notifiers from "../../../global/constants/NotificationConstants";
import registerStyles from "./Register.styles";
import history from "../../../utils/history";
import {
  CustomButton,
  CustomIcon,
  CustomInput,
} from "../../../global/components";
import {
  registerFormField,
  registrationValidation,
} from "./LoginTypesAndValidation";
import strings from "../../../global/constants/StringConstants";
import hidePasswordIcon from "../../../assets/images/Hide.svg";
import showPasswordIcon from "../../../assets/images/Show.svg";
import InputMask from "react-input-mask";
import backIcon from "../../../assets/icons/BackArrow.svg";

interface CustomProps {
  setOtpData: Function;
}

const Register = (props: CustomProps) => {
  // useTitle(strings.SignUpTitle);
  const classes = registerStyles;
  const emailRegex = strings.regex;
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(registerFormField);
  const [showPassword, setShowPassword] = useState(false);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleMaskInputChange = (formFillEvent: React.ChangeEvent<any>) => {
    const response = getFormattedNumbers(formFillEvent.target.value);
    setFormFields({
      ...formFields,
      contact: {
        value: response.number,
        error: "",
      },
    });
  };

  const handleRegister = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        const response = await registerAPI({
          firstName: formFields.firstName.value,
          lastName: formFields.lastName.value,
          contactNumber: formFields.contact.value,
          emailAddress: formFields.email.value,
          password: formFields.password.value,
        });
        setIsLoading(false);
        openSuccessNotification(response.message);
        props.setOtpData({
          token: response.access,
          email: formFields.email.value,
        });
        history.push(urls.otpViewPath);
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleRegister();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = registrationValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getSignUpScreen = () => {
    return (
      <Box sx={classes.getSignUpScreen}>
        <Box sx={classes.innerGetSignUpBox}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{ cursor: "pointer", pr: 2 }}
              onClick={() => history.push(urls.homeViewPath)}
            >
              <CustomIcon icon={<img src={backIcon} alt="Module Not Found..."/>} />
            </Box>
            <Typography sx={classes.getHeading}>Create Account</Typography>
          </Box>
          <Box sx={classes.label} pb={1}>
            <Typography sx={classes.labelText} className="SignUpValidation">
              First Name
            </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter First Name"
            id="firstName"
            type="firstName"
            name="firstName"
            value={formFields.firstName.value}
            onChange={handleOnChangeInputField}
            error={
              !isTruthy(formFields.firstName.value) &&
              formFields.firstName.error
            }
          />
          <Box sx={classes.label} pb={1}>
            <Typography sx={classes.labelText} className="SignUpValidation">
              Last Name
            </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter Last Name"
            id="lastName"
            type="lastName"
            name="lastName"
            value={formFields.lastName.value}
            onChange={handleOnChangeInputField}
            error={
              !isTruthy(formFields.lastName.value) && formFields.lastName.error
            }
          />

          <Box sx={classes.label} pb={1}>
            <Typography sx={classes.labelText}>Contact Number</Typography>
          </Box>
          <InputMask
            mask="(999) 999-9999"
            value={formFields?.contact.value}
            name="contact"
            onChange={handleMaskInputChange}
          >
            {() => (
              <TextField
                placeholder="Enter Contact Number"
                required
                sx={classes.textField}
                name="contact"
              />
            )}
          </InputMask>

          <Box sx={classes.label} pb={1}>
            <Typography sx={classes.labelText} className="loginValidation">
              Email
            </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter Email Address"
            id="email"
            type="email"
            name="email"
            value={formFields.email.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
          <Box sx={classes.label} borderColor="red" pb={1}>
            <Typography sx={classes.labelText}>Password </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            sx={classes.textRadios}
            placeHolder="••••••••"
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formFields.password.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={
              !isTruthy(formFields.password.value) && formFields.password.error
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <Box
                      component="img"
                      src={showPassword ? showPasswordIcon : hidePasswordIcon}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box marginTop={"20px"} width={"100%"}>
            <CustomButton
              label="Register"
              onClick={handleRegister}
              disabled={isLoading}
              loading={isLoading}
              customClasses={classes.signBtn}
            />
          </Box>
          <Box pt={2} display={"flex"}>
            <Typography sx={classes.labelText}>
              Already have an account?
            </Typography>
            <Typography
              sx={{ ...classes.labelText, color: "#f15e22", cursor: "pointer" }}
              onClick={() => history.push(urls.loginViewPath)}
            >
              Login
            </Typography>
          </Box>
          <Box sx={classes.footer}>
            <Typography sx={classes.footerTypo}>
              Panacea Insights &copy; {getYear()} Panacea Project is a
              Corporation Classified under (IRC) Section 501 (C)(3).
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
      }}
      marginTop={{ xs: "50px", md: "0" }}
    >
      {getSignUpScreen()}
    </Box>
  );
};

export default Register;
