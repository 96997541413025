import {
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  pinkDarkColor,
  theme,
} from "../../../utils/styles";

const ForgotPasswordStyles = {
  select: {
    borderRadius: "20px",
    border: "1px solid black",
    width: "100% !important",
  },
  error: {
    borderRadius: "8px",
    outline: "none",
    borderColor: "red !important",
    width: "100% !important",
  },
  label: { display: "flex", height: "20px", marginTop: "30px" },
  labelIcon: { color: "black" },
  labelText: {
    marginLeft: "6px",
    ...mediumFont,
  },
  formCenter: {
    ...centerItemFlex,
  },

  headingCenter: {
    ...mediumFont,
    fontSize: getRelativeFontSize(),
    mt: 2,
    ...centerItemFlex,
  },
  joinMailzzyHeadingCenter: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    mt: 2,
    ...centerItemFlex,
    color: pinkDarkColor,
  },
  textRadious: {
    borderRadius: "100px",
  },
  getHeading: {
    ...mediumFont,
    fontSize: getRelativeFontSize(20),
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
  },
  center: {
    ...mediumFont,
    alignItem: "center",
  },
  getForgotPasswordScreen: {
    display: "grid",
    height: "100%",
    alignItems: "center",
    mt: 15,
    [theme.breakpoints.down("md")]: {
      mt: 10,
    },
  },
  innerGetForgotPasswordBox: {
    maxWidth: "100%",
    maxHeight: "100%",
    padding: 4,
  },
  forgetPasswordWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  signBtn: {
    width: "100%",
  },
  errorStyling: {
    paddingLeft: "10px",
  },
  footer: {
    bottom: 0,
    alignItems: "center",
    width: "80%",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    textAlign: "center",
  },
} as const;

export default ForgotPasswordStyles;
