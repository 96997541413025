import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
} from "../../../../utils/styles";

const blogItemStyle = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "10px",
    borderColor: "grey",
    borderWidth: "2px",
    boxShadow: "0px 3px 10px 3px rgba(0, 0, 0, 0.2)",
    minHeight: "350px",
  },
  imgWrapper: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    marginBottom: "0.5rem",
  },
  headlineText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: "rgb(34, 34, 34)",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    minHeight: "60px",
  },
  desriptionText: {
    ...regularFont,
    fontSize: getRelativeFontSize(-1),
    position: "relative",
    paddingRight: "0.6rem",
    color: "#a9a9a9",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    mt: 1,
    mb: 2,
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  blogButton: {
    marginLeft: "auto",
    cursor: "pointer",
    borderRadius: "20px",
    textAlign: "center",
    width: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    height: "30px",
    backgroundColor: "white",
    border: "2px solid black",
    color: "#000000",
    "&:hover": {
      backgroundColor: "black",
      transition: "0.5s",
      color: "#FFFFFF",
    },
  },
  shareButton: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    width: "auto",
    color: "#90c8be",
    cursor: "pointer",
    justifyContent: "center",
    "&.hover": {
      color: "#FFFFFF",
    },

    "& .item": {
      position: "relative",
      transition: "background 0.3s ease-in-out",
      "& .sub-menu": {
        position: "absolute",
        top: "50px",
        width: "200px",
        transformOrigin: "top",
        transform: "rotateX(-90deg)",
        transition: "transform 0.3s linear",
        backgroundColor: "#ebebeb",
        color: "#000000",
        zIndex: 2,
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        px: 2,
        py: 2,
      },
      "&:hover": {
        "& .sub-menu": {
          transform: "rotateX(0deg)",
        },
      },
    },
  },
} as const;

export default blogItemStyle;
