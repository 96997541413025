import urls from "../global/constants/UrlConstants";
import strings from "../global/constants/StringConstants";
import { AuthMap } from "../models/interfaces";
import { store } from "./store";
import history from "./history";
import { logOutAction } from "../redux/authSlice";

const AuthMapper = {
  Admin: [
    strings.HOME,
    strings.TOPICS,
    strings.BLOG,
    strings.ADDBLOG,
    strings.VIEWBLOG,
    strings.PROFILE,
  ],
  User: [
    strings.HOME,
    strings.TOPICS,
    strings.BLOG,
    strings.ADDBLOG,
    strings.VIEWBLOG,
    strings.PROFILE,
  ],
} as AuthMap;

const ListOfMenus = [
  {
    text: "Home",
    key: "home",
    link: urls.homeViewPath,
    pageName: strings.HOME,
  },
  {
    text: "Hydroponics",
    key: "Hydroponics",
    link: urls.hydroponicsViewPath,
    pageName: strings.TOPICS,
  },
  {
    text: "Technology",
    key: "Technology",
    link: urls.technologyViewPath,
    pageName: strings.TOPICS,
  },
  {
    text: "Innovation",
    key: "Innovation",
    link: urls.innovationViewPath,
    pageName: strings.TOPICS,
  },
  {
    text: "Wellness",
    key: "Wellness",
    link: urls.wellnessViewPath,
    pageName: strings.TOPICS,
  },
  {
    text: "Finance",
    key: "Finance",
    link: urls.financeViewPath,
    pageName: strings.TOPICS,
  },
];

export const GenerateMenu = () => {
  const generatedMenu: any[] = [];
  ListOfMenus.forEach((menu: any) => {
    generatedMenu.push(menu);
  });
  return generatedMenu;
};

export const doesUserHasAccessTo = (componentName: string) => {
  const role = "Admin";
  if (!doesRoleExistInLocalStorage(role)) {
    return [];
  }
  const userAuthorizedPages = AuthMapper[role];
  return userAuthorizedPages.includes(componentName);
};

export const generatedSubMenu = (subMenu: any) => {
  const role = "";
  if (!doesRoleExistInLocalStorage(role)) {
    return [];
  }
  const userRole = AuthMapper[role];
  const generatedMenu: any[] = [];
  subMenu.forEach((menu: any) => {
    if (userRole?.includes(menu.pageName)) {
      generatedMenu.push(menu);
    }
  });
  return generatedMenu;
};

export const homePageRedirection = () => {
  return urls.homeViewPath;
};

const doesRoleExistInLocalStorage = (role: string) => {
  if (!role) {
    store.dispatch(logOutAction());
    history.push(urls.loginViewPath);
    return false;
  }
  return true;
};
