const topicsStyles = {
  mainContainer: {
    // maxWidth: "1600px",
    width: "100%",
    margin: "0 auto",
    p: 2,
  },
  header: {
    color: "#2e2e2e",
    fontSize: "50px",
    // fontFamily: "Oxygen", sans-serif,
    marginBottom: "25px",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  pagination: {
    marginTop: "40px",
    marginBottom: "20px",
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: `#000000 ! important`,
    },
  },
  dropZoneBox: {
    paddingTop: "16px",
    width: "100%",
    // minHeight: "350px",
    fontSize: "12px",
    borderRadius: "10px",
    border: "2px dashed #E7E7E7",
  },
  dropZoneHeader: {
    fontWeight: 700,
    position: "relative",
    textAlign: "center",
    color: "#000000",
    fontSize: "25px",
    pb: 2,
  },
  dividerStyle: {
    width: "100%",
    margin: "0 auto",
    border: "1.5px dashed #E7E7E7",
  },
  dropZoneText: {
    position: "relative",
    // textAlign: "justify",
    color: "#828282",
    fontSize: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    px: 2,
    // border: "1px dashed grey",
    // pb: 5,
  },
  btnStyle: {
    background: "#F15E22",
    color: "#000000",
    borderRadius: "10px",
    width: "200px",
    height: "45px",
    fontWeight: 600,
    "&:hover": {
      background: "#F15E22",
      color: "#000000",
    },
  },
  summaryHeader: {
    fontWeight: 700,
    position: "relative",
    textAlign: "center",
    color: "#000000",
    fontSize: "25px",
    pb: 2,
  },
  acceptedFilesText: {
    position: "relative",
    textAlign: "center",
    color: "#828282",
    fontSize: "16px",
    pb: 5,
  },
} as const;

export default topicsStyles;
