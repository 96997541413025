import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import appDrawerStyles from "./AppDrawer.styles";
import { useEffect, useState } from "react";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import { theme } from "../../../utils/styles";
import { NavLink } from "react-router-dom";
import { store } from "../../../utils/store";
import {
  CloseButton,
  CustomIcon,
} from "../../../global/components";
import {
  logOutAction,
  selectAuthenticated,
  selectUserEmail,
  selectUserName,
} from "../../../redux/authSlice";
import { useAppSelector } from "../../../utils/hooks";
import {
  generatedSubMenu,
  GenerateMenu,
} from "../../../utils/AuthorizationManager";
import React from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import PanaceaInsightsLogo from "../../../assets/icons/PanaceaInsightsLogo1.svg";
import logoutIcon from "../../../assets/icons/logoutIcon.svg";

interface CustomProps {
  setMenuMobileVisible?: Function;
  isActive?: boolean;
}

const AppDrawer = (props: CustomProps) => {
  const classes = appDrawerStyles;
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const userName = useAppSelector(selectUserName);
  const userEmail = useAppSelector(selectUserEmail);
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>();
  const [optionItems, setOptionItems] = useState<any[]>([]);
  const [subOptionItems, setSubOptionItems] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState({
    mainMenu: "HOME",
    subMenu: "",
  });
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    generateAppDrawer();
  }, []);

  useEffect(() => {
    getUrl();
  });

  const getUrl = () => {
    let tempUrl = window.location.pathname;
    tempUrl = tempUrl.substring(0, tempUrl.indexOf("/", 1));
    setUrl(tempUrl);
  };

  const generateAppDrawer = () => {
    setOptionItems(GenerateMenu());
  };

  const handleLogout = () => {
    setTimeout(() => {
      store.dispatch(logOutAction());
    }, 1000);
  };

  const handleRedirection = (
    redirectionURL: string,
    option?: any,
    isSubMenu?: boolean
  ) => {
    if (option.subMenu?.length > 0) {
      setSelectedOption({
        mainMenu: option.key,
        subMenu: option.subMenu[0].key,
      });
      history.push(option.subMenu[0].link);
    } else {
      if (isSubMenu) {
        setSelectedOption({
          ...selectedOption,
          subMenu: option.key,
        });
      } else {
        setSelectedOption({
          mainMenu: option.key,
          subMenu: "",
        });
      }
      history.push(redirectionURL);
    }
    props.setMenuMobileVisible && props.setMenuMobileVisible(false);
  };

  const isActiveTab = (pathName: string) => {
    return (
      "/" + pathName.toLocaleLowerCase() ===
      window.location.pathname.toLocaleLowerCase()
    );
  };

  const getSubMenuData = (option: any, index: number) => {
    if (optionItems.length === 1) {
      setOpen(false);
      generateAppDrawer();
      setSubOptionItems([]);
    } else {
      const subMenu = generatedSubMenu(option.subMenu);
      setSubOptionItems(subMenu);
      setOptionItems([option]);
      setOpen(true);
    }
  };

  const getSubMenuOptions = (option: any, index: number) => {
    return (
      <React.Fragment key={index}>
        <NavLink
          to={option.key}
          style={(isActive) =>
            isActive
              ? { ...classes.selectedMenuOption }
              : { ...classes.menuOption }
          }
        >
          <ListItem
            onClick={() => getSubMenuData(option, index)}
            sx={[
              url === option.key && !open
                ? classes.subMenuItems
                : classes.menuItems,
            ]}
          >
            <ListItemIcon>
              <img
                alt="Module Not Found..."
                src={
                  url === option.key && !open ? option.activeIcon : option.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={classes.navBarLabel}>{option.text}</Typography>
                {open ? <ChevronLeft /> : <ChevronRight />}
              </Box>
            </ListItemText>
          </ListItem>

          {option.subMenu && (
            <Collapse in={open}>
              {subOptionItems.map((data: any, index: number) => {
                return (
                  <NavLink
                    to={data.key}
                    activeStyle={classes.selectedMenuOptionListItem}
                    style={classes.navLink}
                  >
                    <ListItem sx={classes.menuItems}>
                      <ListItemIcon>
                        {isActiveTab(data.key) ? (
                          <img
                            alt="Module Not Found..."
                            src={option.activeIcon}
                          />
                        ) : (
                          <img alt="Module Not Found..." src={option.icon} />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={classes.navBarLabel}>
                          {data.text}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </NavLink>
                );
              })}
            </Collapse>
          )}
        </NavLink>
      </React.Fragment>
    );
  };

  const getMenuOptions = (option: any, index: number) => {
    return (
      <React.Fragment key={index}>
        <NavLink
          to={option.key}
          style={(isActive) =>
            isActive
              ? { ...classes.selectedMenuOption }
              : { ...classes.menuOption }
          }
        >
          <ListItem
            onClick={() => {
              handleRedirection(option.link, option);
            }}
            sx={classes.menuItems}
          >
            {/* <ListItemIcon sx={classes.listItemIconStyle}>
              {isActiveTab(option.key) ? (
                <img alt="Module Not Found..." src={option.activeIcon} />
              ) : (
                <img alt="Module Not Found..." src={option.icon} />
              )}
            </ListItemIcon> */}
            <ListItemText>
              <Typography
                sx={
                  isActiveTab(option.key)
                    ? classes.activeNavBarLabel
                    : classes.navBarLabel
                }
              >
                {option.text}
              </Typography>
            </ListItemText>
          </ListItem>
        </NavLink>
      </React.Fragment>
    );
  };

  const getNewMenuOptions = () => {
    return (
      <List dense>
        {optionItems.map((option, index) => {
          return (
            <>
              {option.subMenu
                ? getSubMenuOptions(option, index)
                : getMenuOptions(option, index)}
            </>
          );
        })}
      </List>
    );
  };

  const getPanaceaogo = () => {
    return (
      <Box
        sx={classes.logoBox}
        onClick={() => {
          history.push(urls.homeViewPath);
        }}
      >
        <img alt="Module Not Found..." src={PanaceaInsightsLogo} />
      </Box>
    );
  };

  const getCloseButton = () => {
    return (
      <CloseButton
        onClick={() =>
          props.setMenuMobileVisible && props.setMenuMobileVisible(false)
        }
      />
    );
  };

  const getUserProfile = () => {
    return (
      <>
        <Box display={"flex"} alignItems={"center"}>
          <Box sx={classes.iconList}>
            <Typography className="profileText" sx={classes.profileText}>
              {userName.charAt(0).toUpperCase()}
            </Typography>
          </Box>
          <Box textAlign={"initial"} pl={1}>
            <Typography sx={classes.addressText}>{userName}</Typography>
            <Typography sx={{ ...classes.addressText, color: "#cf6b42" }}>
              {userEmail}
            </Typography>
          </Box>
        </Box>
        <Box pt={2} display={"flex"} onClick={handleLogout}>
          <Box>
            <CustomIcon
              icon={<img alt="Module Not Found..." src={logoutIcon} />}
            />
          </Box>
          <Typography sx={{ ...classes.navBarLabel, pl: 1 }}>Logout</Typography>
        </Box>
      </>
    );
  };

  const getLogin = () => {
    return (
      <Box pt={5} pb={2} pl={4}>
        {!isAuthenticated ? (
          <Typography
            sx={{ ...classes.navBarLabel, pl: 0 }}
            onClick={() => history.push(urls.loginViewPath)}
          >
            Login
          </Typography>
        ) : (
          getUserProfile()
        )}
      </Box>
    );
  };

  const getAppDrawer = () => {
    return (
      <>
        <Box sx={classes.drawer}>
          <Box sx={classes.drawerWidth}>
            <Box>
              {!isDesktop && getCloseButton()}
              {getPanaceaogo()}
            </Box>
            <Box sx={classes.menuOptionsHeight}>
              {getLogin()}
              {getNewMenuOptions()}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return getAppDrawer();
};

export default AppDrawer;
