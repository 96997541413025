import { Grid } from "@mui/material";
import BlogItem from "../BlogItem/BlogItem";

const BlogList = (props: any) => {
  const handleAddToSummary = (blog: any) => {
    props.handleSelectedBlogs(blog);
  };

  return (
    <>
      {props.is_latest
        ? props.blogs.map((blog: any) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <BlogItem blog={blog} is_latest={props?.is_latest} />
            </Grid>
          ))
        : props.blogs.map((blog: any) => (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <BlogItem
                blog={blog}
                is_latest={props?.is_latest}
                isAdded={handleAddToSummary}
              />
            </Grid>
          ))}
    </>
  );
};

export default BlogList;
