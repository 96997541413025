import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../../../global/components/PrivateRoute/PrivateRoute";
import urls from "../../../global/constants/UrlConstants";
import AppHeader from "../AppHeader/AppHeader";
import layoutStyles from "./Layout.styles";
import AppFooter from "../AppFooter/AppFooter";
import { useAppSelector } from "../../../utils/hooks";
import { selectAuthenticated } from "../../../redux/authSlice";
import PageNotFound from "../../PageNotFound/PageNotFound";
import strings from "../../../global/constants/StringConstants";
import Home from "../../Home/Home";
import Topics from "../../Topics/Topics";
import ChildPage from "../../Topics/Components/Childpage/Childpage";
import LandingPage from "../../LandingPage/LandingPage";
import SearchBlogs from "../../Blogs/SearchBlogs/SearchBlogs";
import AddBlog from "../../Blogs/AddBlog/AddBlog";
import Profile from "../../Profile/Profile";
import ViewBlog from "../../Blogs/ViewBlog/ViewBlog";

interface CustomProps {
  location?: Location;
}

const Layout = (props: CustomProps) => {
  const classes = layoutStyles;
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const location = props.location?.pathname?.split("/")[1].toLowerCase();
  const isAuthenticated = useAppSelector(selectAuthenticated);

  useEffect(() => {
    location === strings.LOGIN ||
    location === strings.REGISTER ||
    location === strings.FORGOT_PASSWORD ||
    location === strings.OTP
      ? setIsVisible(false)
      : setIsVisible(true);
  });

  const getContent = () => {
    return (
      <Box sx={classes.content}>
        <Switch>
          <Route exact path={urls.homeViewPath} component={Home} />
          <Route
            exact
            path={[
              urls.hydroponicsViewPath,
              urls.technologyViewPath,
              urls.innovationViewPath,
              urls.wellnessViewPath,
              urls.financeViewPath,
            ]}
            component={Topics}
          />
          <Route
            exact
            path={`${urls.searchViewPath}/:search`}
            component={SearchBlogs}
          />
          <Route
            exact
            path={`${urls.blogPageViewPath}/:id/:blogName`}
            component={ChildPage}
          />
          <Route
            exact
            path={[
              urls.loginViewPath,
              urls.registerViewPath,
              urls.forgotPasswordViewPath,
              urls.otpViewPath,
            ]}
            component={LandingPage}
          />
          <Route
            exact
            path={`${urls.viewBlogViewPath}/:id`}
            component={ViewBlog}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.addBlogViewPath}
            component={AddBlog}
            componentName={strings.ADDBLOG}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.profileViewPath}
            component={Profile}
            componentName={strings.PROFILE}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={""}
            component={PageNotFound}
            componentName={strings.PAGENOTFOUND}
          />
        </Switch>
      </Box>
    );
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getLayout = () => {
    return (
      <>
        {isVisible && <AppHeader toggleDrawer={toggleDrawer}></AppHeader>}
        <Box
          sx={
            isVisible ? classes.root : { ...classes.root, marginTop: "-120px" }
          }
        >
          {getContent()}
        </Box>
        {isVisible && <AppFooter />}
      </>
    );
  };

  return getLayout();
};

export default Layout;
