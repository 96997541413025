import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useAppSelector } from "../../../utils/hooks";
import appHeaderStyles from "./AppHeader.styles";
import AppDrawer from "../AppDrawer/AppDrawer";
import {
  CustomButton,
  CustomIcon,
  CustomInput,
} from "../../../global/components";
import PanaceaInsightsLogo from "../../../assets/icons/PanaceaInsightsLogo1.svg";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import SearchIcon from "@mui/icons-material/Search";
import {
  logOutAction,
  selectAuthenticated,
  selectUserEmail,
  selectUserName,
} from "../../../redux/authSlice";
import { store } from "../../../utils/store";
import logoutIcon from "../../../assets/icons/logoutIcon.svg";
import ProfileIcon from "../../../assets/icons/Profile.svg";
import { theme } from "../../../utils/styles";

interface CustomProps {
  toggleDrawer: Function;
}

const AppHeader = (props: CustomProps) => {
  const classes = appHeaderStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const userName = useAppSelector(selectUserName);
  const userEmail = useAppSelector(selectUserEmail);
  const [menuMobileVisible, setMenuMobileVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const pages = [
    { name: "Hydroponics" },
    { name: "Technology" },
    { name: "Innovation" },
    { name: "Wellness" },
    { name: "Finance" },
  ];

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    isAuthenticated && setOpen(true);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLogout = () => {
    handleCloseMenu();
    setTimeout(() => {
      store.dispatch(logOutAction());
    }, 1000);
  };

  const handleProfile = () => {
    history.push(urls.profileViewPath);
    handleCloseMenu();
  };

  const handleMenuMobileVisibility = (event: any) => {
    setMenuMobileVisible(event.currentTarget);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuMobileVisible(false);
    };

  const getMobileMenuDrawer = () => {
    return (
      <Drawer
        open={menuMobileVisible}
        onClose={toggleDrawer}
        sx={{
          paper: classes.menuMobile,
        }}
      >
        <AppDrawer setMenuMobileVisible={setMenuMobileVisible} />
      </Drawer>
    );
  };

  const handlePageChange = (pageName: string) => {
    setSearch("");
    history.push({
      pathname: `/${pageName}`,
      state: {
        pageName,
      },
    });
  };

  const handleSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value) {
      setSearch(event.target.value.trim());
    } else {
      setSearch("");
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      search
        ? history.push(`${urls.searchViewPath}/${search}`)
        : history.push(urls.homeViewPath);
    }
  };

  const getPanaceaLogo = () => {
    return (
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() => {
          history.push(urls.homeViewPath);
        }}
      >
        <img
          src={PanaceaInsightsLogo}
          alt="Module Not Found..."
          height={"100px"}
          width={"100px"}
        />
      </Box>
    );
  };

  const getUserProfile = () => {
    return (
      <>
        <Box display={"flex"} alignItems={"center"}>
          <Box textAlign={"end"} pr={1}>
            <Typography sx={classes.addressText}>{userName}</Typography>
            <Typography sx={{ ...classes.addressText, color: "#d3d3d3" }}>
              {userEmail}
            </Typography>
          </Box>
          <Box sx={classes.iconList} onClick={handleClick}>
            <Typography className="profileText" sx={classes.profileText}>
              {userName.charAt(0).toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "20px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              p: 2,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) translateX(-50%) rotate(50deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <MenuItem
            onClick={handleProfile}
            sx={{
              "&:hover": {
                borderRadius: "20px",
                boxShadow: "0px 8px 40px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Box sx={{ width: "30px" }}>
              <CustomIcon
                icon={<img src={ProfileIcon} alt="Module Not Found..." />}
              />
            </Box>
            <Typography sx={classes.menuItemText}>Profile</Typography>
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            sx={{
              "&:hover": {
                borderRadius: "20px",
                boxShadow: "0px 8px 40px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Box sx={{ width: "30px" }}>
              <CustomIcon
                icon={<img src={logoutIcon} alt="Module Not Found..." />}
              />
            </Box>
            <Typography sx={classes.menuItemText}>Logout</Typography>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const getAppHeader = () => {
    return (
      <>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            height: "120px",
            backgroundColor: "#000000",
          }}
        >
          <Toolbar
            sx={{
              "&.MuiToolbar-root": {
                px: 0,
                [theme.breakpoints.down("lg")]: {
                  height: "120px",
                },
              },
            }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {isDesktop ? (
                <Grid item xl={6.5} lg={6.5}>
                  <Grid
                    container
                    spacing={{ xl: 4, lg: 2 }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    p={2}
                  >
                    <Grid item>{getPanaceaLogo()}</Grid>
                    {pages.map((page: any) => {
                      return (
                        <Grid item>
                          <Typography
                            sx={
                              window.location.pathname === `/${page.name}`
                                ? classes.selectedHeaderText
                                : classes.headerText
                            }
                            onClick={() => handlePageChange(page.name)}
                          >
                            {page.name}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={1} sm={1} xs={1}>
                  <IconButton
                    size="large"
                    edge="start"
                    aria-label="open drawer"
                    sx={{
                      display: { lg: "none" },
                      color: "#FFFFFF",
                      ml: 2,
                      p: 0,
                    }}
                    onClick={handleMenuMobileVisibility}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              )}

              <Grid
                item
                xl={!isAuthenticated ? 4 : 2}
                lg={!isAuthenticated ? 4 : 2}
                md={!isAuthenticated ? 8 : 5}
                sm={!isAuthenticated ? 8 : 4}
                xs={8}
                pr={2}
              >
                <CustomInput
                  variant="standard"
                  placeHolder="Search..."
                  name="searchBlogs"
                  onChange={handleSearchOnChange}
                  onKeyPress={handleKeypress}
                  value={search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  xl={!isAuthenticated ? 1 : 3.5}
                  lg={!isAuthenticated ? 1 : 3.5}
                  md={!isAuthenticated ? 2 : 5}
                  sm={!isAuthenticated ? 2 : 7}
                  xs={!isAuthenticated ? 1 : 7}
                  display={"flex"}
                  justifyContent={!isAuthenticated ? "center" : "space-between"}
                  alignItems={"center"}
                >
                  {!isAuthenticated ? (
                    <Typography
                      sx={classes.headerText}
                      onClick={() => history.push(urls.loginViewPath)}
                    >
                      Login
                    </Typography>
                  ) : (
                    <>
                      <Box>
                        <CustomButton
                          customClasses={{
                            color: "#f15e22",
                            border: "1px solid #f15e22",
                            maxWidth: "200px",
                            "&:hover": {
                              background: "#f15e22",
                              color: "#000000",
                            },
                          }}
                          onClick={() => history.push(urls.addBlogViewPath)}
                          label={"Add Blogs"}
                        />
                      </Box>
                      {getUserProfile()}
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>

        {getMobileMenuDrawer()}
      </>
    );
  };

  return getAppHeader();
};

export default AppHeader;
