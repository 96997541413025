import { useMediaQuery, Box, Grid } from "@mui/material";
import { theme } from "../../utils/styles";
import landingPageStyles from "./LandingPage.styles";
import { Location } from "history";
import strings from "../../global/constants/StringConstants";
import Login from "./components/Login";
import backgroundImage from "../../assets/images/back.webp";
import PanaceaInsightsLogo from "../../assets/icons/PanaceaInsightsLogo.svg";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import OTPVerification from "./components/OTPVerification";
import { useState } from "react";

interface CustomProps {
  location?: Location;
}

const LandingPage = (props: CustomProps) => {
  const classes = landingPageStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [otpData, setOtpData] = useState({
    token: "",
    email: "",
  });

  const staticImageComponent = () => {
    return (
      <Box
        sx={{
          height: "100vh",
          // backgroundColor: "#282945",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img alt="Module Not Found..." src={backgroundImage} height={"100%"} />
      </Box>
    );
  };

  const getComponentBasedOnURL = () => {
    const location = props.location?.pathname?.split("/")[1].toLowerCase();
    switch (location) {
      case strings.LOGIN: {
        return <Login />;
      }
      case strings.REGISTER: {
        return <Register setOtpData={setOtpData} />;
      }
      case strings.FORGOT_PASSWORD: {
        return <ForgotPassword />;
      }
      case strings.OTP: {
        return <OTPVerification otpData={otpData} />;
      }
      default: {
        return <Login />;
      }
    }
  };

  const getLandingPage = () => {
    return (
      <Box>
        <Grid container>
          {isDesktop && (
            <Grid item xs={12} sm={12} md={12} lg={8} xl={7.5}>
              {staticImageComponent()}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4.5}
            position="relative"
            sx={{
              background: "#FFFFFF",
              maxWidth: "100%",
              minHeight: "100vh",
              padding: 4,
              height: "100%",
            }}
          >
            <Box sx={classes.logo}>
              <img alt="Module Not Found..." src={PanaceaInsightsLogo} />
            </Box>
            <Box
              sx={{
                marginTop: "-140px",
                [theme.breakpoints.down("md")]: {
                  marginTop: "-100px",
                },
              }}
            >
              {getComponentBasedOnURL()}
            </Box>
          </Grid>
        </Grid>
        {/* {getFooter()} */}
      </Box>
    );
  };
  return getLandingPage();
};

export default LandingPage;
