export const addBlogForm = () => {
  return {
    key: {
      value: "",
      error: "",
    },
    title: {
      value: "",
      error: "",
    },
    headline: {
      value: "",
      error: "",
    },
    summary: {
      value: "",
      error: "",
    },
    image: {
      value: "",
      path: "",
      error: "",
    },
    content: {
      value: "",
      error: "",
    },
  } as any;
};

export const generateHeadlineValidation = (
  blogData: any,
  isSubmit: boolean
) => {
  let errors = blogData;
  let isValid = true;
  const key = blogData.key.value;
  const title = blogData.title.value;
  const headline = blogData.headline.value;
  const summary = blogData.summary.value;
  const content = blogData.content.value;
  if (isSubmit) {
    if (!key) {
      errors.key.error = "Please select topic name";
      isValid = false;
    }
    if (!title) {
      errors.title.error = "Please enter blog title";
      isValid = false;
    }
    if (!headline) {
      errors.headline.error = "Please enter blog headline";
      isValid = false;
    }
    if (!summary) {
      errors.summary.error = "Please enter blog summary";
      isValid = false;
    }
  } else {
    if (!content) {
      errors.content.error = "Please enter blog content";
      isValid = false;
    }
  }

  return { isValid, errors };
};
