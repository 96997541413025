import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./Childpage.css";
import BlogList from "../BlogList/BlogList";
import * as topicsService from "../../Topics.service";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import childpageStyle from "./Childpage.styles";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { CustomIcon } from "../../../../global/components";
import history from "../../../../utils/history";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";

export const ChildPage = (props: any) => {
  const classes = childpageStyle;
  const [blogs, setBlogs] = useState([]);
  const [blogData, setBlogData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const id = window.location.pathname.split("/")[2];
  const title = window.location.pathname.split("/")[3];
  const baseURL = window.location.origin;

  let mainUrl = `/blog/${id}/${title}`;
  const facebook =
    "https://www.facebook.com/sharer/sharer.php?u=https://www.panaceinsights.com";
  let facebookLink = facebook + mainUrl;
  const linkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${baseURL}`;
  let linkedInLink = linkedIn + mainUrl;
  const twitter = `https://twitter.com/intent/tweet?url=${baseURL}`;
  let twitterLink = twitter + mainUrl;
  let mail =
    "mailto:?subject=" +
    `${title}` +
    ` | Panacea Insights&body=#Happy Breathing %0D%0ACheck out this blog ${baseURL}` +
    mainUrl;

  const copyUrl = () => {
    navigator.clipboard.writeText(baseURL + mainUrl);
    openSuccessNotification("URL copied to your clipboard");
  };

  const shareButtons = [
    { name: "facebook", link: facebookLink, icon: FacebookOutlinedIcon },
    { name: "linkedin", link: linkedInLink, icon: LinkedInIcon },
    { name: "twitter", link: twitterLink, icon: TwitterIcon },
    { name: "mail", link: mail, icon: EmailOutlinedIcon },
    { name: "copyURL", link: "", icon: InsertLinkOutlinedIcon },
  ];

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const [blogData, recommendedBlogs] = await Promise.all([
        topicsService.getBlogData(id),
        topicsService.getRecommendedBlogs(id),
      ]);
      setBlogData(blogData);
      setBlogs(recommendedBlogs);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleShare = (link: string) => {
    link ? window.open(link) : copyUrl();
  };

  return (
    <Box sx={classes.mainBox}>
      <Box mb={5} sx={{ cursor: "pointer" }} onClick={() => history.goBack()}>
        <CustomIcon
          icon={<KeyboardBackspaceOutlinedIcon htmlColor="#999999" />}
        />
        <Typography ml={4} sx={{ color: "#999999" }}>
          Go Back
        </Typography>
      </Box>

      <Box>
        <Typography sx={classes.headerText}>{blogData?.headline}</Typography>
        <Box display={"flex"}>
          <Typography sx={classes.subHeaderText}>
            Original Author {blogData?.author} &nbsp; | &nbsp; Published on{" "}
            {blogData?.date} &nbsp; | &nbsp; Source : {blogData?.source}
          </Typography>
        </Box>
        <Box onClick={() => window.open(blogData?.url)}>
          <img src={blogData?.image} alt="cover" style={classes.blogImage} />
        </Box>
        <Grid container spacing={6} pt={2}>
          {shareButtons.map((button: any) => {
            return (
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() => handleShare(button.link)}
              >
                <CustomIcon icon={<button.icon fontSize="large" />} />
              </Grid>
            );
          })}
        </Grid>
        <Box sx={classes.blogSummaryBox}>
          <Typography sx={classes.blogSummaryTitle}>
            Panacea Takeaway
          </Typography>
          <Typography>{blogData?.summary}</Typography>
        </Box>
      </Box>
      {console.log(blogs)}
      <Box className="blog-wrap-recommended" style={{ width: "100%" }} pb={5}>
        <strong className="recommend-title">People Also Read This!</strong>
        <Box className="blog-recommend">
          <Grid container direction="row" spacing={1}>
            <BlogList blogs={blogs} is_latest={false} />
          </Grid>
        </Box>
      </Box>
      <CustomLoader isLoading={isLoading} />
    </Box>
  );
};

export default ChildPage;
