import {
  Box,
  Button,
  //  Card,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import blogItemStyle from "./BlogItem.style";
import history from "../../../../utils/history";
import { CustomIcon } from "../../../../global/components";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import * as topicServices from "../../Topics.service";

const BlogItem = (props: any) => {
  const classes = blogItemStyle;
  const [isLiked, setIsLiked] = useState(props.blog.isLiked);
  const baseURL = window.location.origin;

  let url = `/blog/${props.blog.blog_id}/${props.blog.headline}`;
  let mainUrl = url.replace(/\s/g, "-");
  const facebook =
    "https://www.facebook.com/sharer/sharer.php?u=https://www.panaceinsights.com";
  let facebookLink = facebook + mainUrl;
  const linkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${baseURL}`;
  let linkedInLink = linkedIn + mainUrl;
  const twitter = `https://twitter.com/intent/tweet?url=${baseURL}`;
  let twitterLink = twitter + mainUrl;
  let mail =
    "mailto:?subject=" +
    `${props.blog.headline}` +
    ` | Panacea Insights&body=#Happy Breathing %0D%0ACheck out this blog ${baseURL}` +
    mainUrl;

  const copyUrl = () => {
    navigator.clipboard.writeText(baseURL + mainUrl);
    openSuccessNotification("URL copied to your clipboard");
  };

  const shareButtons = [
    { name: "facebook", link: facebookLink, icon: FacebookOutlinedIcon },
    { name: "linkedin", link: linkedInLink, icon: LinkedInIcon },
    { name: "twitter", link: twitterLink, icon: TwitterIcon },
    { name: "mail", link: mail, icon: EmailOutlinedIcon },
    { name: "copyURL", link: "", icon: InsertLinkOutlinedIcon },
  ];

  const handleShare = (link: string) => {
    link ? window.open(link) : copyUrl();
  };

  const likeBlogs = async () => {
    try {
      await topicServices.likeBlogs(props.blog.blog_id, !isLiked);
      setIsLiked(!isLiked);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  return (
    <Box sx={classes.mainBox}>
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() =>
          history.push({
            pathname: mainUrl,
            state: { id: props.blog.id, title: props.blog.headline },
          })
        }
      >
        <img style={classes.imgWrapper} src={props.blog.image} alt="cover" />
      </Box>
      <Typography sx={classes.headlineText}>{props.blog.headline}</Typography>
      <Typography sx={classes.desriptionText}>{props.blog.summary}</Typography>
      <Box display={"flex"} alignItems={"center"} pr={2}>
        {/* <Tooltip title={"Add blogs for summary"}>
          <IconButton onClick={() => props.isAdded(props.blog)}>
            <AddIcon style={{ color: "black" }} />
          </IconButton>
        </Tooltip> */}
        <Tooltip title={"Like"}>
          <IconButton onClick={likeBlogs}>
            <FavoriteIcon style={{ color: isLiked ? "red" : "black" }} />
          </IconButton>
        </Tooltip>
        {/* <Card
          sx={{ boxShadow: "none" }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        > */}
        <Box sx={classes.shareButton}>
          <Box className="item">
            <IconButton style={{ color: "black" }}>
              <ShareIcon />
            </IconButton>
            <Box className="sub-menu">
              <Grid container>
                {shareButtons.map((button: any) => {
                  return (
                    <Tooltip title={button.name}>
                      <Grid
                        item
                        xl={2.4}
                        onClick={() => handleShare(button.link)}
                      >
                        <CustomIcon icon={<button.icon />} />
                      </Grid>
                    </Tooltip>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* </Card> */}
        <Button
          sx={classes.blogButton}
          onClick={() =>
            history.push({
              pathname: mainUrl,
              state: { id: props.blog.id, title: props.blog.headline },
            })
          }
        >
          Blog ➝
        </Button>
      </Box>
    </Box>
  );
};

export default BlogItem;
