import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";

export interface AuthState {
  authenticated: boolean;
  accessToken: string;
  loading: boolean;
  email: string;
  firstName: string;
  lastName: string;
  refreshToken: string;
  contact: string;
}

const initialState: AuthState = {
  authenticated: false,
  accessToken: "",
  loading: false,
  email: "",
  firstName: "",
  lastName: "",
  refreshToken: "",
  contact: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        accessToken: string;
        email: string;
        firstName: string;
        lastName: string;
        refreshToken: string;
        // contact: string;
      }>
    ) => {
      state.authenticated = action.payload.authenticated;
      state.accessToken = action.payload.accessToken;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.refreshToken = action.payload.refreshToken;
      // state.contact = action.payload.contact;
    },
    addLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logOutAction: (state, action: {}) => {
      state.authenticated = false;
      state.loading = false;
      state.accessToken = "";
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.contact = "";
    },
  },
});

export const { loginAction, logOutAction, addLoading } = authSlice.actions;

export const selectAuthenticated = (state: RootState) =>
  state.auth.authenticated;
export const selectaccessToken = (state: RootState) => state.auth.accessToken;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectUserEmail = (state: RootState) => state.auth.email;
export const selectUserName = (state: RootState) =>
  state.auth.firstName + " " + state.auth.lastName;

export default authSlice.reducer;
