import React, { useEffect, useState, useRef } from "react";
import BlogList from "./Components/BlogList/BlogList";
import {
  Box,
  // Button,
  Container,
  // Divider,
  Grid,
  // IconButton,
  Pagination,
  Typography,
  // createTheme,
  // useMediaQuery,
} from "@mui/material";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { isTruthy, openErrorNotification } from "../../helpers/methods";
import notifiers from "../../global/constants/NotificationConstants";
// import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import topicsStyle from "./Topics.styles";
import * as topicsService from "./Topics.service";

// const theme = createTheme({
//   typography: {
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       "Poppins_Regular",
//       "Poppins_Medium",
//       "Poppins_Bold",
//       "Poppins_Black",
//       "sans-serif",
//     ].join(","),
//   },
// });

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 30px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "30px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const Topics = (props: any) => {
  const classes = topicsStyle;
  // const redirectionState = props.location?.state;
  // const topic = redirectionState.pageName;
  const topic = window.location.pathname.split("/")[1] ?? "";
  const [blogs, setBlogs] = useState([]);
  const [selectedBlogs, setSelectedBlogs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  // const [summary, setSummary] = useState("");
  const [page, setPage] = useState<number>(1);
  const [dataPerPage, setDataPerPage] = useState<number>(12);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [open, setOpen] = useState(false);

  // const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const prevTopic = useRef(topic);
  const prevPage = useRef(page);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    document.title = `${topic}`;
    getTopicData();
    if (prevPage.current !== page) {
      if (prevTopic.current !== topic) {
        setSelectedBlogs([]);
        setPage(1);
        setIsSummary(false);
      }
    } else {
      if (prevTopic.current !== topic) {
        setPage(1);
        setIsSummary(false);
      }
      setSelectedBlogs([]);
    }
    prevTopic.current = topic;
    prevPage.current = page;
  }, [topic, page, dataPerPage]);

  const getTopicData = async () => {
    try {
      setBlogs([]);
      setIsLoading(true);
      const response = await topicsService.getTopicData(
        topic,
        dataPerPage,
        page
      );
      setBlogs(response.blogs);
      setTotalPage(response.totalPages);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  // const handleSummary = async () => {
  //   try {
  //     if (selectedBlogs.length > 0) {
  //       const listOfBlogIds = selectedBlogs.map((blog: any) => {
  //         return blog.id;
  //       });
  //       setIsLoading(true);
  //       // const response = await axios.post(
  //       //   `http://192.168.50.171:5002/panacea/api/multi/document/summarizer`,
  //       //   {
  //       //     listOfBlogIds,
  //       //   }
  //       // );
  //       // setSummary(response.data.multiTextSummarizer);
  //       setIsSummary(true);
  //       setIsLoading(false);
  //     } else {
  //       setOpen(true);
  //       setIsSummary(false);
  //     }
  //   } catch (error: any) {
  //     setIsLoading(false);
  //     openErrorNotification(
  //       isTruthy(error.errorMessage)
  //         ? error.errorMessage
  //         : notifiers.GENERIC_ERROR
  //     );
  //   }
  // };

  const handleChange = (event: any) => {
    setDataPerPage(event.target.value);
  };

  const handleSelectedBlogs = (selectedBlog: any) => {
    if (!selectedBlogs.includes(selectedBlog)) {
      selectedBlogs.length < 3 &&
        setSelectedBlogs([...selectedBlogs, selectedBlog]);
    }
  };

  // const handleRemoveBlogs = (value: any) => {
  //   setSelectedBlogs(selectedBlogs.filter((item: any) => item !== value));
  //   if (selectedBlogs.filter((item: any) => item !== value).length === 0) {
  //     setIsSummary(false);
  //   }
  // };

  const getBlogBox = () => {
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={3} justifyContent={"center"}>
          <BlogList
            blogs={blogs}
            is_latest={false}
            handleSelectedBlogs={handleSelectedBlogs}
          />
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={classes.paginationWrapper}
          >
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel>Blogs per page</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={dataPerPage}
                onChange={handleChange}
                input={<BootstrapInput />}
                defaultValue={12}
              >
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              sx={classes.pagination}
              showFirstButton
              showLastButton
              count={totalPage}
              color="primary"
              page={page}
              onChange={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // const getSummaryBox = () => {
  //   return (
  //     <Grid item xl={3} lg={3}>
  //       <Box sx={classes.dropZoneBox}>
  //         <Box pb={2}>
  //           <Typography sx={classes.dropZoneHeader}>
  //             Get summary here!
  //           </Typography>
  //           <Divider sx={classes.dividerStyle} />
  //         </Box>

  //         <Box pb={1} px={1}>
  //           {selectedBlogs.map((blogs: any, index: number) => {
  //             return (
  //               <Box sx={{ border: "1px solid grey", display: "flex", mb: 1 }}>
  //                 <Box height={"120px"}>
  //                   <img
  //                     src={blogs.image}
  //                     alt="Module Not Found..."
  //                     height={"120px"}
  //                     width={"120px"}
  //                   />
  //                 </Box>
  //                 <Box>
  //                   <Typography className="blogItem-headline-small">
  //                     {blogs.headline}
  //                   </Typography>
  //                 </Box>
  //                 <IconButton
  //                   size="small"
  //                   sx={{ marginBottom: "auto" }}
  //                   onClick={() => handleRemoveBlogs(blogs)}
  //                   // color="black"
  //                 >
  //                   <CloseIcon />
  //                 </IconButton>
  //               </Box>
  //             );
  //           })}
  //         </Box>

  //         <Box pb={2} sx={{ display: "flex", justifyContent: "center" }}>
  //           <Button sx={classes.btnStyle} onClick={handleSummary}>
  //             Get Summary
  //           </Button>
  //         </Box>
  //         {isSummary && (
  //           <>
  //             <Divider
  //               sx={{
  //                 ...classes.dividerStyle,
  //                 marginBottom: "16px",
  //               }}
  //             />
  //             <Box>
  //               <Typography sx={classes.summaryHeader}>Summary</Typography>
  //               <Divider sx={classes.dividerStyle} />
  //             </Box>
  //             <Box p={3}>
  //               <Typography textAlign={"justify"}>{summary}</Typography>
  //             </Box>
  //           </>
  //         )}
  //       </Box>
  //     </Grid>
  //   );
  // };

  return (
    <Box>
      <Grid container justifyContent={"center"}>
        <Box pt={5} borderBottom={"double"}>
          <Typography sx={classes.header}>{topic}</Typography>
        </Box>
      </Grid>
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={classes.mainContainer}>
          {getBlogBox()}
          {/* {isDesktop && getSummaryBox()} */}
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
          >
            <Alert
              onClose={() => setOpen(false)}
              severity="warning"
              sx={{
                width: "100%",
                background: "#F15E22",
                color: "#000000",
                fontWeight: 600,
              }}
            >
              Please add some blogs!
            </Alert>
          </Snackbar>
          <CustomLoader isLoading={isLoading} />
        </Grid>
      </Container>
    </Box>
  );
};

export default Topics;
