import { mediumFont, getRelativeFontSize, theme } from "../../utils/styles";

const landingPageStyles = {
  footer: {
    bottom: 0,
    alignItems: "center",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    textAlign: "center",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    marginTop: 4,
    mx: 4,
    [theme.breakpoints.down("md")]: {
      left: "35px",
    },
  },
  welcomeText: {
    mx: 4,
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
  },
} as const;

export default landingPageStyles;
