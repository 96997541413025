import {
  mediumFont,
  drawerWidth,
  getRelativeFontSize,
  regularFont,
  theme,
  textLightColor,
  purplePrimaryColor,
} from "../../../utils/styles";

const appDrawerStyles = {
  drawer: {
    display: "flex",
    height: "100vh",
    width: drawerWidth,
    backgroundColor: "#000000",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    padding: 0,
    margin: 0,
    zIndex: 0,
  },
  drawerWidth: {
    width: drawerWidth,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "hidden",
    overflowX: "hidden",

    [theme.breakpoints.down("xl")]: {
      width: drawerWidth,
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  navLink: {
    textDecoration: "none",
  },
  menuItems: {
    margin: "4px",
    paddingLeft: "4px",
  },
  listItemIconStyle: {
    minWidth: "35px",
  },
  subMenuItems: {
    borderLeft: "4px solid",
  },
  menuOptionsHeight: {
    paddingRight: "15px",
    paddingLeft: "40px",
    height: "80vh",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "#ffffff",
    },
  },
  menuOptionListItem: {
    ...mediumFont,
    cursor: "pointer",
    borderLeft: "4px solid transparent",
  },
  selectedMenuOptionListItem: {
    color: purplePrimaryColor,
    display: "flex",
    borderLeft: "4px solid",
    height: "30px",
  },
  menuOption: {
    display: "flex",
    ...mediumFont,
    textDecoration: "none",
    color: textLightColor,
    borderLeft: "4px solid transparent",
    flexDirection: "column",
    marginBottom: "17px",
  },
  selectedMenuOption: {
    display: "flex",
    ...mediumFont,
    textDecoration: "none",
    color: purplePrimaryColor,
    borderRadius: "52px",
    flexDirection: "column",
    background: "#FFFFFF",
    marginBottom: "17px",
  },
  navBarLabel: {
    ...mediumFont,
    paddingLeft: "20px",
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(3),
  },
  activeNavBarLabel: {
    ...mediumFont,
    paddingLeft: "20px",
    fontSize: getRelativeFontSize(3),
    color: "#da551f",
  },
  logoBox: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: "20px 0",
  },
  supportTicketBox: {
    borderRadius: "15px",
    mx: 5,
    mt: 1,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {},
    marginBottom: "10%",
  },
  btnBox: {
    ...regularFont,
    textTransform: "none",
  },
  imgBox: {
    background: "#22BAB6",
    width: "32px",
    height: "32px",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  getCampaignWrapper: {
    height: "25vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "16px",
    "& img": {
      width: "125px",
      height: "auto",
    },
  },
  getCampaignIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  getCampaignImg: {
    width: "137px",
    height: "auto",
  },
  logOutWrapper: {
    height: "10vh",
    display: "flex",
    padding: "0 16px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "15px",
    [theme.breakpoints.down("xl")]: {},
  },
  logOutLeft: {
    display: "flex",
    alignItems: "center",
  },
  squareBox: {
    width: "46px",
    height: "46px",
    borderRadius: "15px",
    background: "#DBDBDB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarStyle: {
    ...mediumFont,
    fontSize: getRelativeFontSize(10),
  },
  avatarFirstName: {
    color: "#212121",
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    marginLeft: "8px",
    wordBreak: "break-all",
  },
  addressText: {
    ...regularFont,
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  iconList: {
    position: "relative",
    height: "50px",
    width: "50px",
    display: "-webkit-box",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "5px solid #f15e22",
    borderRadius: "50%",
    overflow: "hidden",
    transition: "all 500ms ease",
    zIndex: "1",
    cursor: "pointer",
    marginRight: "5px",
    "&:after": {
      backgroundColor: "#f4875a",
      borderRadius: "30px",
      content: `''`,
      display: "block",
      height: "100%",
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      transform: "translate(0, -100%) ",
      transformOrigin: "bottom",
      transition: "all .5s ease-in-out",
      willChange: "transform",
      zIndex: -1,
    },
    "&:hover": {
      "& .profileText": {
        transition: "all .5s ease-in-out",
        color: "#FFFFFF",
      },
      "&:after": {
        transform: "translate(0, 0)",
      },
    },
  },
  profileText: {
    ...regularFont,
    fontWeight: "700",
    fontSize: "25px",
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      transition: "all .5s ease-in-out",
      color: "#f4875a",
    },
  },
} as const;

export default appDrawerStyles;
